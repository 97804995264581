import * as React from "react";
import FullCalendar from "@fullcalendar/react";
import {ProList, ModalForm, ActionType} from '@ant-design/pro-components';
import {useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import './style.less'
import {Form, InputNumber, message, Modal, Popconfirm, Select, Space, Tag, TimePicker, Tooltip} from "antd";
import FormItem from "antd/es/form/FormItem";
import {
  addClassSchedule,
  classAndOneToOneList,
  classScheduleViewList, removeRecord,
  teacherAllList, undoRecord
} from "@/services/tmh/api";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {closeTab} from "@/assets/js/publicFunc";

const ClassCalendar: React.FC = () => {

  const [state, setState] = useState({
    weekendsVisible: true,
    currentEvents: []
  });

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0});
  const [scheduleForm] = Form.useForm();

  const [targetDate, setTargetDate] = useState('');

  const [scheduleFormVisible, setScheduleFormVisible] = useState(false);

  const [recordVisible, setRecordVisible] = useState(false);

  const [targetTimeInterval, setTargetTimeInterval] = useState('');

  const [currentTeacherId, setCurrentTeacherId] = useState('');

  const [schoolClass, setSchoolClass] = useState([]);

  const [teacher, setTeacher] = useState([]);

  const [events, setEvents] = useState([]);

  const autoReLoad = useRef<any>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [totalHours, setTotalHours] = useState(0);

  const [totalRecordHours, setTotalRecordHours] = useState(0);

  const history = useHistory();

  const [ids, setIds] = useState([]);

  const timeFormat = 'HH:mm';

  const proListRef = useRef<ActionType>();

  const [selectedTeacher, setSelectedTeacher] = useState('');

  const [defaultSelected, setDefaultSelected] = useState(true);

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
  };

  let buttonTextCompoundInput = {
    today: "今天",
    month: "月",
    week: "周",
    day: "天",
  }

  useEffect(() => {
    getClassAllList().then();
    getTeacherSelect().then();
    const handleReceiveMessage = (event) => {
      if (event.data === '打印一行字') {
        let api = autoReLoad.current.getApi();
        handleGetEvents(api.view.currentStart, api.view.currentEnd, currentTeacherId).then();
      }
    };

    const broadcastChannel = new BroadcastChannel('printChannel');
    broadcastChannel.addEventListener('message', handleReceiveMessage);

    const calendarApi = autoReLoad.current.getApi();

    // 创建一个 Ant Design 的 Select 组件作为下拉框
    const customSelect = (
      <div>
        <span>教师：</span>
        <Select defaultValue={defaultSelected ? '' : selectedTeacher} // Set the defaultValue based on state
                placeholder={'请选择上课教师'}
                showSearch
                style={{width: 120}}
                filterOption={(input, option) =>
                  (option.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => {
                  setCurrentTeacherId(value);
                  setSelectedTeacher(value);
                  setDefaultSelected(false);
                  handleGetEvents(autoReLoad.current.getApi().view.activeStart, autoReLoad.current.getApi().view.activeEnd, value);
                }}
        >
          <option value={''} selected>全部</option>
          {teacher}
        </Select>
        &nbsp;&nbsp;
        <span>总课时：{totalHours}，</span>
        <span>记录课时：{totalRecordHours}，</span>
        <span>未记录课时：{totalHours == 0 ? 0 : totalHours - totalRecordHours}</span>
      </div>
    );

    const prevCustomContainer = document.querySelector('.custom-container');
    if (prevCustomContainer) {
      prevCustomContainer.remove();
    }

    // 获取 FullCalendar 的表头 DOM 元素
    const headerToolbarEl = calendarApi.el.querySelector('.fc-toolbar');

    const firstToolbarChunkEl = headerToolbarEl.querySelector('.fc-toolbar-chunk');

    // 创建一个容器 div，包含今天按钮和下拉框
    const customContainer = document.createElement('div');
    customContainer.className = 'custom-container';
    ReactDOM.render(customSelect, customContainer);

    // 将自定义容器替换为 FullCalendar 的表头内容
    firstToolbarChunkEl.appendChild(customContainer);

    firstToolbarChunkEl.style.display = 'flex';
    firstToolbarChunkEl.style.alignItems = 'center';

    return () => {
      broadcastChannel.removeEventListener('message', handleReceiveMessage);
      broadcastChannel.close();
    };
  }, [totalHours, totalRecordHours]);

  const getClassAllList = async () => {
    try {
      const res = await classAndOneToOneList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id} value={item.id}>{item.name}</option>);
        });
        setSchoolClass(temp);
      }
    } catch (error) {
    }
  }

  const getTeacherSelect = async () => {
    try {
      const res = await teacherAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(
            <option key={item.id} value={item.id}>
              {`${item.name}`}
            </option>);
        });
        setTeacher(temp);
      }
    } catch (error) {
    }
  }


  let customContent = () => {
    return (<div>
      {tooltipContent.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br/>
        </span>
      ))}
    </div>)
  }


  let handleEventMouseEnter = (info) => {
    const eventEl = info.el;
    const eventRect = eventEl.getBoundingClientRect();
    const eventX = eventRect.left + window.pageXOffset + eventRect.width / 2;
    const eventY = eventRect.top + window.pageYOffset + 10;
    setTooltipContent(info.event.title);
    setTooltipPosition({top: eventY, left: eventX});
    setTooltipVisible(true);
  }

  let handleEventMouseLeave = () => {
    setTooltipVisible(false);
  }

  const handleDateSelect = (selectInfo) => {
    // 判断选中的时间段是否跨越多天
    if (selectInfo.start.getUTCDay() !== selectInfo.end.getUTCDay()) {
      message.error('请选择同一天内的时间段！').then(r => {
      });
      return;
    }
    setScheduleFormVisible(true);
    const startTime = new Date(selectInfo.start.getTime() + 8 * 3600 * 1000);
    const endTime = new Date(selectInfo.end.getTime() + 8 * 3600 * 1000);
    setTargetDate(startTime.toISOString().replace(/T.*$/, ''));
    const startTimeStr = startTime.toISOString().split('T')[1].replace(/\.\d+Z$/, '');
    const [startHours, startMinutes] = startTimeStr.split(':');
    const endTimeStr = endTime.toISOString().split('T')[1].replace(/\.\d+Z$/, '');
    const [endHours, endMinutes] = endTimeStr.split(':');
    setTargetTimeInterval(startHours + ":" + startMinutes + '-' + endHours + ":" + endMinutes);
  }


  let handleEventClick = (eventInfo) => {
    const ids = eventInfo.event.extendedProps.ids;
    setIds(ids);
    if (proListRef.current) {
      proListRef.current.reload().then();
    }
    setRecordVisible(true);
  }

  let handleEvents = (events) => {
    setState({
      weekendsVisible: state.weekendsVisible,
      currentEvents: events
    })
  }


  function handleEventContent(eventInfo) {
    const {timeText} = eventInfo;
    const currentEvent = eventInfo.event;
    // 查找当前事件在合并后的事件数组中的索引
    const index = events.findIndex((evt) => evt.id === currentEvent.id);
    let titleHTML;
    // 只有在合并后的事件数组中找到当前事件时，才进行渲染
    if (index !== -1) {
      // 格式化标题
      titleHTML = events[index].title.replaceAll("\n", '<br>');
    } else {
      // 如果合并后的事件数组中找不到当前事件，则返回默认的事件渲染
      titleHTML = currentEvent.title.replaceAll("\n", '<br/>');
    }
    // 合并重叠事件的时间和标题信息
    titleHTML = titleHTML.replace(/✔/g, '&nbsp;<span style="color: green;">✔</span>');
    // titleHTML = titleHTML.replace(/❌/g, '&nbsp;⚠️');
    titleHTML = titleHTML.replace(/❌/g, '&nbsp;❌');

    const content =
      `<div>
        ${timeText}
        <br />
        ${titleHTML}
      </div>`;

    return {html: content};
  }


  function handleEventDidMount(eventInfo) {
    const contentElement = eventInfo.el.querySelector('.fc-event-main');
    if (!contentElement) {
      return;
    }
    setTimeout(() => {
      if (contentElement.scrollHeight > contentElement.clientHeight ||
        contentElement.scrollWidth > contentElement.clientWidth) {
        contentElement.style.overflow = 'hidden';
      }
    });
  }


  // 重新创建 TimePicker 组件，传递最新的 targetTimeInterval 作为 defaultValue
  const TimePickerWrapper = () => {
    const [startTime, endTime] = targetTimeInterval.split('-');
    let startMoment = moment(startTime, timeFormat);
    let endMoment = moment(endTime, timeFormat);
    const diffInMilliseconds = endMoment.diff(startMoment); // 计算时间差值（毫秒）
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60); // 将毫秒转换为小时
    if (diffInHours === 1) {
      // 如果时间差正好是1小时，则将endTime提前10分钟
      endMoment.subtract(10, 'minutes');
      setTargetTimeInterval(startMoment.format(timeFormat) + "-" + endMoment.format(timeFormat));
    }

    return (
      <TimePicker.RangePicker
        key={targetTimeInterval}
        style={{width: '80%'}}
        defaultValue={[
          startTime ? startMoment : null,
          endTime ? endMoment : null,
        ]}
        format={timeFormat}
        onChange={(values => {
            if (values) {
              setTargetTimeInterval(values[0].format(timeFormat) + "-" + values[1].format(timeFormat));
            }
          }
        )}
      />
    );
  }

  // 获取当前的时间范围
  const getCurrentTimeRange = (view) => {
    let start = new Date(view.currentStart);
    let end = new Date(view.currentEnd);
    return {start, end};
  };

  const handleViewChange = async (eventInfo) => {
    const {start, end} = getCurrentTimeRange(eventInfo.view);
    await handleGetEvents(start, end, currentTeacherId);
  };

  const handleGetEvents = async (start, end, teacherId) => {
    const startStr = moment(start).format('YYYY-MM-DD HH:mm:ss');
    const endStr = moment(end).format('YYYY-MM-DD HH:mm:ss');
    let statusNum = 0;
    setTimeout(async () => {
      try {
        // 添加排课
        const res = await classScheduleViewList({'startTime': startStr, 'endTime': endStr, 'teacherId': teacherId});
        if (res.code === '0') {
          let mergedEvents = [];
          res.data.forEach((evt) => {
            if (evt.status && evt.status === 1) {
              statusNum++;
            }
            const currentStart = new Date(evt.start);
            const currentEnd = new Date(evt.end);
            let overlappingEvent = mergedEvents.find(
              (mergedEvt) =>
                new Date(mergedEvt.start) < currentEnd &&
                new Date(mergedEvt.end) > currentStart
            );
            if (overlappingEvent) {
              overlappingEvent.title += '\n' + evt.title;
              // 更新开始时间为最早时间
              if (new Date(overlappingEvent.start) > currentStart) {
                overlappingEvent.start = evt.start;
              }

              // 更新结束时间为最晚的时间
              if (new Date(overlappingEvent.end) < currentEnd) {
                overlappingEvent.end = evt.end;
              }
              // 更新ids属性
              overlappingEvent.ids = [...(overlappingEvent.ids || []), evt.id];
            } else {
              mergedEvents.push({...evt, ids: [evt.id]});
            }
          });
          setEvents(mergedEvents);
          setTotalHours(res.total);
          setTotalRecordHours(statusNum);
          return;
        } else {
          message.error(res.msg);
        }
      } catch (error) {
      }
    }, 500);
  }

  return (
    <div>
      <FullCalendar
        ref={autoReLoad}
        locale="zh-cn"
        firstDay={1}
        allDaySlot={false}
        defaultAllDay={false}
        slotMinTime={"08:00"}
        slotMaxTime={"22:00"}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        buttonText={buttonTextCompoundInput}
        aspectRatio={2.66}
        eventTimeFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          hour12: false,
          meridiem: "short",
        }}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          hour12: false,
          meridiem: "short"
        }}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        initialView='timeGridWeek'
        editable={false}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={state.weekendsVisible}
        events={events}
        select={handleDateSelect}
        eventContent={handleEventContent} // custom render function
        eventClick={handleEventClick}
        eventDidMount={handleEventDidMount}
        eventMouseEnter={handleEventMouseEnter}
        eventMouseLeave={handleEventMouseLeave}
        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
        // 禁止跨天
        selectAllow={(selectInfo) => {
          // 允许选择的日期范围是同一天内的时间段
          return selectInfo.start.getUTCDay() === selectInfo.end.getUTCDay();
        }}
        // 切换视图触发
        datesSet={handleViewChange}
      />
      {tooltipVisible && (
        <Tooltip title={customContent} visible={tooltipVisible} getPopupContainer={() => document.body}>
          <span
            style={{
              position: 'absolute',
              top: tooltipPosition.top,
              left: tooltipPosition.left,
              zIndex: 9999
            }}
          />
        </Tooltip>
      )}

      <ModalForm
        form={scheduleForm}
        {...layout}
        title={'自由排课'}
        layout={'horizontal'}
        width="500px"
        onVisibleChange={setScheduleFormVisible}
        visible={scheduleFormVisible}
        onFinish={async (values) => {
          if (isSubmitting) return; // 如果正在提交中，则不再继续处理
          setIsSubmitting(true); // 设置为正在提交状态
          values.timePeriod = targetTimeInterval;
          values.mode = 1;
          values.freeScheduleDate = targetDate;
          setTimeout(async () => {
            try {
              // 添加排课
              const data = await addClassSchedule(values);
              if (data.code === '0') {
                message.success('添加成功！');
                setScheduleFormVisible(false);
                scheduleForm.resetFields();
                await handleGetEvents(autoReLoad.current.getApi().view.currentStart, autoReLoad.current.getApi().view.currentEnd, currentTeacherId);
                return;
              } else {
                message.error(data.msg);
              }
            } catch (error) {
            } finally {
              setIsSubmitting(false); // 请求结束，设置为非提交状态，允许再次提交
            }
          }, 500);
        }}
      >
        <FormItem
          label={'班级/1对1'}
          name="classId"
          rules={[
            {
              required: true,
              message: '班级/1对1不能为空',
            },
          ]}
        >
          <Select
            placeholder={'请选择班级/1对1'}
            showSearch
            style={{width: '70%'}}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {schoolClass}
          </Select>
        </FormItem>
        <Form.Item
          label={'上课教师'}
          name="teacherId"
          rules={[
            {
              required: true,
              message: '上课教师不能为空',
            },
          ]}
        >
          <Select
            placeholder={'请选择上课教师'}
            showSearch
            style={{width: '70%'}}
            filterOption={(input, option) =>
              (option.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {teacher}
          </Select>
        </Form.Item>
        <Form.Item
          label={'教师课时'}
          name={'teacherAddHour'}
          initialValue={1}
          rules={[{required: true, message: '教师课时不能为空'}]}
        >
          <InputNumber defaultValue={1} min={0} style={{width: '70%'}}/>
        </Form.Item>
        <FormItem
          label={'上课日期'}
        >
          {targetDate}
        </FormItem>


        <Form.Item
          label={'上课时段'}
          name={'timePeriod'}
        >
          <TimePickerWrapper/>
        </Form.Item>
      </ModalForm>

      <Modal
        title="排课详情"
        onOk={() => {
          setRecordVisible(false);
        }}
        onCancel={() => {
          setRecordVisible(false);
        }}
        visible={recordVisible}
        afterClose={() => {
          setRecordVisible(false);
        }}
      >
        {
          recordVisible && (
            <ProList
              actionRef={proListRef}
              request={async () => {
                let result = null;
                await classScheduleViewList({ids: ids}).then(res => {
                  result = res;
                })
                return Promise.resolve({
                  data: result.data,
                  total: result.total,
                  success: result.success
                });
              }}
              showActions="always"
              showExtra="always"
              metas={{
                title: {
                  dataIndex: 'timePeriod',
                },
                description: {
                  dataIndex: 'detailTitle',
                },
                subTitle: {
                  render: (text, row) => {
                    return (
                      <Space size={0}>
                        <Tag
                          color={row.status === 0 ? 'red' : 'green'}>{row.status === 0 ? '未记录' : '已记录'}
                        </Tag>
                      </Space>
                    );
                  },
                },
                actions: {
                  render: (text, row) => {
                    if (row.status === 0) {
                      return [
                        <a
                          key="a"
                          onClick={() => {
                            setRecordVisible(false);
                            closeTab("/eduCenter/teachingLog/add");
                            history.push("/eduCenter/teachingLog/add?id=" + row.id + "&editOnly=1&isScheduleView=1");
                          }}
                        >编辑</a>,
                        <Popconfirm
                          key="b"
                          title="是否确定删除当前记录?"
                          onConfirm={() => {
                            removeRecord({'id': row.id}).then(
                              // success
                              (res) => {
                                if (res.code === '0') {
                                  message.success(res.msg);
                                  handleGetEvents(autoReLoad.current.getApi().view.currentStart, autoReLoad.current.getApi().view.currentEnd, currentTeacherId)
                                    .then();
                                  setRecordVisible(false);
                                } else {
                                  message.error(res.msg);
                                }
                              },
                            );
                          }
                          }
                          okText="是"
                          cancelText="否"
                        >
                          <a href="#">删除</a>
                        </Popconfirm>,
                        <a
                          key="c"
                          onClick={() => {
                            setRecordVisible(false);
                            closeTab("/eduCenter/teachingLog/add");
                            history.push("/eduCenter/teachingLog/add?id=" + row.id + "&isScheduleView=1");
                          }}
                        >记上课</a>
                      ]
                    } else {
                      return [
                        <a
                          key="a"
                          onClick={() => {
                            setRecordVisible(false);
                            closeTab("/eduCenter/teachingLog/add");
                            history.push("/eduCenter/teachingLog/add?id=" + row.id + "&readOnlyFlag=1&isScheduleView=1");
                          }}
                        >详情</a>,
                        <Popconfirm
                          key='c'
                          title="是否确定撤销当前记录?"
                          onConfirm={() => {
                            undoRecord({'id': row.id}).then(
                              (res) => {
                                if (res.code === '0') {
                                  message.success(res.msg);
                                  handleGetEvents(autoReLoad.current.getApi().view.currentStart, autoReLoad.current.getApi().view.currentEnd, currentTeacherId)
                                    .then();
                                  setRecordVisible(false);
                                } else {
                                  message.error(res.msg);
                                }
                              },
                            );
                          }
                          }
                          okText="是"
                          cancelText="否"
                        >
                          <a href="#">撤销</a>
                        </Popconfirm>
                      ];
                    }
                  }
                },
              }}
            />
          )
        }

      </Modal>

    </div>
  )
}
export default ClassCalendar;
