import React, {useEffect, FC, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {LockOutlined, UserOutlined} from '@ant-design/icons'
import {Form, Input, Button, Alert} from 'antd'
import ReactCanvasNest from 'react-canvas-nest'
import './login.less'
import Logo from '@/assets/img/logo.png'
import {setUserInfo} from '@/assets/js/publicFunc'
import {connect} from 'react-redux'
import * as actions from '@/store/actions'
import {ProFormCheckbox, ProForm} from '@ant-design/pro-components';
import {login} from "@/services/tmh/login";
import {getUserInfo} from "@/App";

interface Props extends ReduxProps {
}

const LoginForm: FC<Props> = ({
                                storeData: {theme, userInfo = {}},
                                setStoreData
                              }) => {
  const locationStateHistory = useHistory();
  const [code, setCode] = useState<string>('0');
  const [type, setType] = useState<string>('account');
  const [submitting, setSubmitting] = useState(false);
  // const {initialState, setInitialState} = useModel('@@initialState');

  useEffect(() => {
    const {token} = userInfo
    if (token) {
      locationStateHistory.push('/');
      return
    }
    // 重置 tab栏为首页
    setStoreData('SET_CURTAB', ['/'])
  }, [locationStateHistory, setStoreData, userInfo]);

  /**
   * 保存token到localStorage
   *
   * @param token
   */
  const saveToken = (token: string) => {
    localStorage.setItem('token', token);
  }

  /** 此方法会跳转到 redirect 参数所在的位置 */
  const goto = () => {
    if (!locationStateHistory)
      return;
    setTimeout(() => {
      locationStateHistory.push('/');
    }, 10);
  };

  // 触发登录方法
  const onFinish = async (values: CommonObjectType<string>) => {
    setSubmitting(true);
    setCode('0');
    setTimeout(async () => {
      try {
        // 登录
        const data = await login({...values, type});
        if (data.code === '0') {
          const currentUser = await getUserInfo(data.token);
          currentUser.token = data.token;
          setUserInfo(currentUser, setStoreData);
          goto();
          return;
        }
        // 如果失败去设置用户错误信息
        setCode(data.code);
      } catch (error) {
      }
      setSubmitting(false);
    }, 500);
  }

  const LoginMessage: React.FC<{
    content: string;
  }> = ({content}) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  const FormView = (
    <div className={'main'}>
      <ProForm
        className={'login-form'}
        name={'login-form'}
        initialValues={{
          autoLogin: true,
        }}
        submitter={{
          searchConfig: {
            submitText: '登录'
          },
          render: (_, dom) => dom.pop(),
          submitButtonProps: {
            loading: submitting,
            size: 'large',
            style: {
              width: '100%',
            },
          },
        }}
        onFinish={onFinish}
        isKeyPressSubmit
      >
        {code !== '0' && (
          <LoginMessage
            content={'用户名或密码错误'}
          />
        )}
        <Form.Item
          name="username"
          rules={[{required: true, message: '请输入用户名'}]}
        >
          <Input placeholder="用户名" prefix={<UserOutlined/>} size="large"/>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{required: true, message: '请输入密码'}]}
        >
          <Input.Password
            placeholder="密码"
            prefix={<LockOutlined/>}
            size="large"
          />
        </Form.Item>
        <div
          style={{
            marginBottom: 24,
          }}
        >
          <ProFormCheckbox noStyle name="autoLogin" >
            自动登录
          </ProFormCheckbox>
        </div>
      </ProForm>
    </div>


  )

  const floatColor = theme === 'default' ? '24,144,255' : '110,65,255'
  return (
    <div className="login-layout" id="login-layout">
      <ReactCanvasNest
        config={{
          pointColor: floatColor,
          lineColor: floatColor,
          pointOpacity: 0.6
        }}
        style={{zIndex: 1}}
      />
      <div className="logo-box">
        <img alt="" className="logo" src={Logo}/>
        <span className="logo-name">三 音 家</span>
      </div>
      {FormView}
    </div>
  )
}

export default connect(
  (state) => state,
  actions
)(LoginForm)
