import  {ProTable,ActionType, ProColumns,ModalForm,PageContainer} from "@ant-design/pro-components";
import {
  classStudentList,
  distributeList,
  distributeStudent,
} from "@/services/tmh/api";
import * as React from "react";
import {Button, Form, message, Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useRef, useState} from "react";
import FormItem from "antd/es/form/FormItem";

interface Props {
  handleIsVisible: Function;
  row: TMH.SchoolClass;
}

const AssignStudentTable: React.FC<Props> = (props) => {

  const [formVisible, setFormVisible] = useState(false);

  const [studentCount, setStudentCount] = useState(0);

  const autoReLoad = useRef<ActionType>();

  const [studentList, setStudentList] = useState([]);

  const getStudentListSelect = async () => {
    try {
      const res = await distributeList(props?.row?.id);
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          // temp.push(<option key={item.id}><span style={{marginRight: '20px'}}>{item.name}</span>{item.remainingHour}
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setStudentList(temp);
      }
    } catch (error) {
    }
  }


  const [form] = Form.useForm();

  const columns: ProColumns[] = [
    {
      title: '学员姓名',
      dataIndex: 'name',
      valueType: 'text',
    },
    {
      title: '性别',
      dataIndex: 'sex',
      valueType: 'text',
      valueEnum: {
        1: {
          text: '男',
        },
        2: {
          text: '女'
        }
      },
      search: false
    },
    {
      title: '年龄',
      dataIndex: 'age',
      valueType: 'text',
      renderText: (val: (number | undefined)) => {
        if (typeof val === 'undefined') {
          return '-';
        } else if (val <= 0) {
          return '-';
        }
        return `${val}岁`
      },
      search: false
    },
    {
      title: '关系',
      dataIndex: 'contactRelation',
      valueType: 'select',
      search: false
    },
    {
      title: '联系电话',
      dataIndex: 'contactPhone',
      valueType: 'text',
      search: false
    },
    {
      title: '已用课时',
      dataIndex: 'usedHour',
      valueType: 'text',
      search: false,
      renderText: (val: string) => {
        if (typeof val === 'undefined') {
          return '-';
        }
        return `${val}课时`;
      }
    },
    {
      title: '剩余课时',
      dataIndex: 'remainingHour',
      valueType: 'text',
      search: false,
      renderText: (val: string) => {
        if (typeof val === 'undefined') {
          return '-';
        }
        return `${val}课时`;
      }
    },
    {
      title: '生日',
      dataIndex: 'birthday',
      valueType: 'text',
      search: false
    },
  ]

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
  };

  const getListData = async () => {
    if (typeof props.row.id === 'undefined') {
      message.error('获取学员列表失败，请联系管理员！');
      return null;
    } else {
      const data = await classStudentList(props.row.id);
      if (data.success && data.total) {
        setStudentCount(data.total);
      }
      return data;
    }
  };


  return (
    <>
      <PageContainer
        title={'分配学员'}
        onBack={() => {
          props.handleIsVisible(false);
        }}
      >
        <ProTable columns={columns}
          // @ts-ignore
                  request={getListData}
                  key='student'
                  actionRef={autoReLoad}
                  toolBarRender={() => [
                    <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
                      getStudentListSelect().then();
                      setFormVisible(true)
                    }}>
                      添加学员
                    </Button>
                  ]}/>
      </PageContainer>

      <ModalForm
        form={form}
        {...layout}
        layout={'horizontal'}
        title={'添加学员'}
        width="400px"
        onVisibleChange={setFormVisible}
        visible={formVisible}
        onFinish={async (values) => {
          values.classId = props.row.id;
          values.lessonId = props.row.lessonId;
          setTimeout(async () => {
            try {
              // 添加学员
              const data = await distributeStudent(values);
              if (data.code === '0') {
                message.success('添加成功！');
                setFormVisible(false);
                if (autoReLoad.current) {
                  autoReLoad.current.reload();
                }
                form.resetFields();
                return;
              }
              // 如果失败去设置用户错误信息
            } catch (error) {
            }
          }, 500);
        }}
      >
        <FormItem
          label={'班级名称'}
        >
          {props.row.name}
        </FormItem>
        <FormItem
          label={'学员'}
          name="studentId"
          rules={[{required: true, message: '请选择学员'}]}
        >
          <Select
            placeholder={'请选择学员'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {/*<option><span style={{marginRight: '25px'}}>姓名</span>{'剩余课时'}</option>*/}
            {studentList}
          </Select>
        </FormItem>
      </ModalForm>
    </>
  );

}

export default AssignStudentTable;
