exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container--3ORSQ {\n  width: 100vw;\n}\n.menu--2wPFA {\n  height: 100vh;\n  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);\n  position: fixed;\n  overflow-y: auto;\n}\n.menu--2wPFA::-webkit-scrollbar {\n  width: 0;\n}\n.content--3HRWU {\n  height: 100%;\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n  width: 100%;\n  margin-left: 220px;\n  overflow-x: hidden;\n  transition: margin 0.2s;\n}\n.content--3HRWU.collapsed--2jsiy {\n  margin-left: 80px;\n}\n.content--3HRWU .mainContent--1RtB_ {\n  background: #141414;\n  margin: 0 16px 16px;\n  padding: 16px;\n  overflow: auto;\n  transition: all 0.5s;\n}\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n", ""]);

// Exports
exports.locals = {
	"container": "container--3ORSQ",
	"menu": "menu--2wPFA",
	"content": "content--3HRWU",
	"collapsed": "collapsed--2jsiy",
	"mainContent": "mainContent--1RtB_"
};