import * as React from "react";
import {
  addSubject,
  subjectList
} from "@/services/tmh/api";
import {Button, Form, Input, message} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {ModalForm,ActionType, ProColumns,ProTable} from "@ant-design/pro-components";
import {useRef, useState} from "react";

const SubjectTable: React.FC = () => {

  const [formVisible, setFormVisible] = useState(false);

  const autoReLoad = useRef<ActionType>();

  const [form] = Form.useForm();

  const fieldLabels = {
    name: '科目名称',
  };


  const columns: ProColumns[] = [
    {
      title: '编号',
      dataIndex: 'id',
      valueType: 'text',
      search: false
    },
    {
      title: '科目名称',
      dataIndex: 'name',
      valueType: 'text',
    },
    {
      title: '状态',
      dataIndex: 'status',
      valueType: 'text',
      valueEnum: {
        true: {
          text: '正常',
        },
        false: {
          text: '下架'
        }
      }
    },
  ]


  return (
    <>
      <ProTable columns={columns}
                request={subjectList}
                key='subject'
                actionRef={autoReLoad}
                toolBarRender={() => [
                  <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setFormVisible(true)
                  }}>
                    新建
                  </Button>
                ]}/>
      <ModalForm
        form={form}
        title={'添加科目'}
        layout={'horizontal'}
        width="400px"
        onVisibleChange={setFormVisible}
        visible={formVisible}
        onFinish={async (values) => {
          setTimeout(async () => {
            try {
              // 添加学员
              const data = await addSubject(values);
              if (data.code === '0') {
                message.success('添加成功！');
                setFormVisible(false);
                if (autoReLoad.current) {
                  autoReLoad.current.reload();
                }
                form.resetFields();
                return;
              }
              // 如果失败去设置用户错误信息
            } catch (error) {
            }
          }, 500);
        }}
      >
        <Form.Item
          label={fieldLabels.name}
          name="name"
          rules={[{required: true, message: '科目名称不能为空'}]}
        >
          <Input placeholder="请输入科目名称"/>
        </Form.Item>
      </ModalForm>
    </>
  );

}

export default SubjectTable;
