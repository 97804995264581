import * as React from 'react';
import {PageContainer} from "@ant-design/pro-components";
import {useState} from "react";
import RoleTree from "@/components/role";
import OneToOneTable from "@/components/eduCenter/oneToOne";

const OneToOneTab: React.FC = () => {

  const [tabKey, setTabKey] = useState<string>('oneToOne');

  const [formVisible, setFormVisible] = useState<boolean>(false);

  const [currRow, setCurrRow] = useState<TMH.SchoolClass>({});

  const [tab, setTab] = useState<number>(0);

  // tab
  const tabList = [
    {
      key: 'oneToOne',
      tab: '学员列表',
    },
    // {
    //   key: 'log',
    //   tab: '班级操作日志',
    // },
  ];

  const renderChildrenByTabKey = () => {
    if (tabKey === 'oneToOne') {
      return <OneToOneTable/>;
    } else if (tabKey === 'log') {
      return <RoleTree/>;
    }
    return null;
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  return (
    <PageContainer
      tabList={tabList}
      tabActiveKey={tabKey}
      onTabChange={handleTabChange}
    >
      {renderChildrenByTabKey()}
    </PageContainer>
  );

}

export default OneToOneTab;
