import {ProColumns, ProTable} from "@ant-design/pro-components";
import {HrStatus} from "@/utils/constants";
import {employeeList} from "@/services/tmh/api";
import * as React from "react";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";


interface Props {
  handleIsVisible: Function;
}

const EmployeeTable: React.FC<Props> = (props) => {

  const history = useHistory();

  const columns: ProColumns[] = [
    {
      title: '员工编号',
      dataIndex: 'id',
      valueType: 'text',
      search: false
    },
    {
      title: '员工名称',
      dataIndex: 'name',
      valueType: 'text',
    },
    {
      title: '手机号',
      dataIndex: 'mobile',
      valueType: 'text',
    },
    {
      title: '性别',
      dataIndex: 'sex',
      valueType: 'text',
      search: false
    },
    {
      title: '所属部门',
      dataIndex: 'departmentName',
      valueType: 'select',
      search: false
    },
    {
      title: '是否是教师',
      dataIndex: 'isTeacher',
      valueType: 'select',
      valueEnum: {
        true: {
          text: '是',
        },
        false: {
          text: '否'
        }
      }
    },
    {
      title: '人事状态',
      dataIndex: 'hrStatus',
      valueType: 'text',
      valueEnum: HrStatus
    },
    {
      title: '生日',
      dataIndex: 'birthday',
      valueType: 'text',
      search: false
    },
    {
      title: '机构账号',
      dataIndex: 'userRole',
      valueType: 'text',
      search: false
    },
  ]


  return (
    <ProTable columns={columns}
              request={employeeList}
              key='employee'
              search={{
                defaultCollapsed:false
              }}
              toolBarRender={() => [
                <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
                  history.push('/inner/employee/add');
                }}>
                  新建
                </Button>
              ]}/>
  );

}

export default EmployeeTable;
