import {CloseCircleOutlined} from '@ant-design/icons';
import {Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Popover, Radio, Row, Select} from 'antd';
import React, {FC, useState} from 'react';
import {PageContainer, FooterToolbar} from '@ant-design/pro-components';
import styles from './style.less';
import {RadioChangeEvent} from "antd/lib/radio/interface";
import {addEmployee} from "@/services/tmh/api";
import {useHistory} from "react-router-dom";
import {closeTabAction} from "@/assets/js/publicFunc";

type InternalNamePath = (string | number)[];

const {Option} = Select;

const fieldLabels = {
  employeeName: '姓名',
  mobile: '手机号',
  sex: '性别',
  email: '邮箱',
  enable: '是否允许登录系统',
  hrStatus: '人事状态',
  departmentId: '所属部门',
  isTeacher: '是否是上课教师',
  createLoginAccount: '是否创建机构账号',
  birthday: '生日',
  username: '用户名',
  password: '密码',
  roleList: '角色',
};


interface ErrorField {
  name: InternalNamePath;
  errors: string[];
}


const EmployeeForm: FC = () => {
  const [form] = Form.useForm();

  const [error, setError] = useState<ErrorField[]>([]);

  const history = useHistory();

  const getErrorInfo = (errors: ErrorField[]) => {
    const errorCount = errors.filter((item) => item.errors.length > 0).length;
    if (!errors || errorCount === 0) {
      return null;
    }
    const scrollToField = (fieldKey: string) => {
      const labelNode = document.querySelector(`label[for="${fieldKey}"]`);
      if (labelNode) {
        labelNode.scrollIntoView(true);
      }
    };
    const errorList = errors.map((err) => {
      if (!err || err.errors.length === 0) {
        return null;
      }
      const key = err.name[0] as string;
      return (
        <li key={key} className={styles.errorListItem} onClick={() => scrollToField(key)}>
          <CloseCircleOutlined className={styles.errorIcon}/>
          <div className={styles.errorMessage}>{err.errors[0]}</div>
          <div className={styles.errorField}>{fieldLabels[key]}</div>
        </li>
      );
    });
    return (
      <span className={styles.errorIcon}>
        <Popover
          title="表单校验信息"
          content={errorList}
          overlayClassName={styles.errorPopover}
          trigger="click"
          getPopupContainer={(trigger: HTMLElement) => {
            if (trigger && trigger.parentNode) {
              return trigger.parentNode as HTMLElement;
            }
            return trigger;
          }}
        >
          <CloseCircleOutlined/>
        </Popover>
        {errorCount}
      </span>
    );
  };


  const [createUser, setCreateUser] = useState(false);

  const [loading, setLoading] = useState(false);

  const change = (e: RadioChangeEvent) => {
    setCreateUser(e.target.value);
  };

  const cancelSubmit = () => {
    closeTabAction(history, '/inner/employee');
  };

  const onFinish = (values: { [key: string]: any }) => {
    setLoading(true);
    setError([]);
    if (values['birthday'] !== undefined) {
      values['birthday'] = values['birthday'].format('YYYY-MM-DD');
    }
    setTimeout(async () => {
      try {
        // 添加员工
        const data = await addEmployee(values);
        if (data.code === '0') {
          message.success('添加成功！');
          closeTabAction(history, '/inner/employee');
          return;
        }
        // 如果失败去设置用户错误信息
      } catch (error) {
      }
    }, 500);
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    setError(errorInfo.errorFields);
  };


  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <PageContainer title={'添加员工'}>
        <Card className={styles.card} bordered={false} title={"员工信息"}>
          <Row gutter={16}>
            <Col lg={6} md={12} sm={24}>
              <Form.Item
                label={fieldLabels.employeeName}
                name="employeeName"
                rules={[{required: true, message: '请输入员工姓名'}]}
              >
                <Input placeholder="请输入员工性别"/>
              </Form.Item>
            </Col>
            <Col xl={{span: 6, offset: 2}} lg={{span: 8}} md={{span: 12}} sm={24}>
              <Form.Item
                label={fieldLabels.mobile}
                name="mobile"
                rules={[{required: true, message: '请输入手机号'}]}
              >
                <Input
                  style={{width: '100%'}}
                  placeholder="请输入手机号"
                />
              </Form.Item>
            </Col>
            <Col xl={{span: 8, offset: 2}} lg={{span: 10}} md={{span: 24}} sm={24}>
              <Form.Item
                label={fieldLabels.sex}
                name="sex"
                initialValue={2}
              >
                <Radio.Group defaultValue={2}>
                  <Radio value={2}>
                    女
                  </Radio>
                  <Radio value={1}>
                    男
                  </Radio>
                  <Radio value={0}>
                    保密
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={6} md={12} sm={24}>
              <Form.Item
                label={fieldLabels.email}
                name="email"
                rules={[{required: true, message: '请输入邮箱'}]}
              >
                <Input
                  style={{width: '100%'}}
                  placeholder="请输入邮箱"
                />
              </Form.Item>
            </Col>
            <Col xl={{span: 6, offset: 2}} lg={{span: 8}} md={{span: 12}} sm={24}>
              <Form.Item
                label={fieldLabels.hrStatus}
                name="hrStatus"
                initialValue={1}
              >
                <Select placeholder="请选择人事状态" defaultValue={1}>
                  <Option value={1} selected>正式员工</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={{span: 8, offset: 2}} lg={{span: 10}} md={{span: 24}} sm={24}>
              <Form.Item
                label={fieldLabels.departmentId}
                name="departmentId"
                initialValue={1003}
              >
                <Select placeholder="请选择部门" defaultValue={1003} style={{width: '80%'}}>
                  <Option value={1003}>三音琴行主校区</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={6} md={12} sm={24}>
              <Form.Item
                label={fieldLabels.birthday}
                name="birthday"
              >
                <DatePicker placeholder={'请选择生日'} style={{width: '100%'}} format={'YYYY-MM-DD'}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 6, offset: 2}} lg={{span: 8}} md={{span: 12}} sm={24}>
              <Form.Item
                label={fieldLabels.isTeacher}
                name="isTeacher"
                initialValue={true}
              >
                <Radio.Group defaultValue={true}>
                  <Radio value={true} checked>
                    是
                  </Radio>
                  <Radio value={false}>
                    否
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xl={{span: 8, offset: 2}} lg={{span: 10}} md={{span: 24}} sm={24}>
              <Form.Item
                label={fieldLabels.createLoginAccount}
                name="createLoginAccount"
                initialValue={false}
              >
                <Radio.Group defaultValue={false} onChange={change}>
                  <Radio value={true}>
                    是
                  </Radio>
                  <Radio value={false} checked>
                    否
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title="机构账号" className={styles.card} bordered={false} hidden={!createUser}>
          <Row gutter={16}>
            <Col lg={6} md={12} sm={24}>
              <Form.Item
                label={fieldLabels.enable}
                name="enable"
                initialValue={true}
              >
                <Radio.Group defaultValue={true}>
                  <Radio value={true} checked>
                    允许
                  </Radio>
                  <Radio value={false}>
                    禁止
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={6} md={12} sm={24}>
              <Form.Item
                label={fieldLabels.username}
                name="userName"
                rules={[{required: createUser, message: '请输出登录名'}]}
              >
                <Input placeholder="请输出登录名"/>
              </Form.Item>
            </Col>
            <Col xl={{span: 6, offset: 2}} lg={{span: 8}} md={{span: 12}} sm={24}>
              <Form.Item
                label={fieldLabels.password}
                name="password"
                rules={[{required: createUser, message: '请输入密码'}]}
              >
                <Input.Password placeholder="请输入密码" autoComplete={"new-passowrd"}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={{span: 18}} md={18} sm={24}>
              <Form.Item
                label={fieldLabels.roleList}
                name="roleList"
              >
                <Checkbox.Group>
                  <Checkbox value="1007">教务</Checkbox>
                  <Checkbox value="1001">教师</Checkbox>
                  <Checkbox value="1003">前台</Checkbox>
                  <Checkbox value="1004">校区主管</Checkbox>
                  <Checkbox value="1005">机构主管</Checkbox>
                  <Checkbox value="1006">财务</Checkbox>
                  <Checkbox value="1002">人事</Checkbox>
                  <Checkbox value="1000">销售员</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </PageContainer>
      <FooterToolbar>
        {getErrorInfo(error)}
        <Button type="default" onClick={cancelSubmit}>
          取消
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          提交
        </Button>
      </FooterToolbar>
    </Form>
  );
};
export default EmployeeForm;
