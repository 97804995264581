import * as React from 'react';
import {PageContainer} from "@ant-design/pro-components";
import {useState} from "react";
import TodayRecordTable from "@/components/eduCenter/teachingLog";
import RecordClass from "@/components/eduCenter/teachingLog/recordClass";
import ClassRecordTable from "@/components/eduCenter/teachingLog/classRecord";

const TeachingLogTab: React.FC = () => {

  const [tabKey, setTabKey] = useState<string>('today');

  const [todayCurrRow, setTodayCurrRow] = useState({});

  const tabList = [
    {
      key: 'today',
      tab: '记上课',
    },
    {
      key: 'classRecord',
      tab: '上课记录',
    },
  ];

  const renderChildrenByTabKey = () => {
    if (tabKey === 'today') {
      return <TodayRecordTable setCurrRow={setTodayCurrRow} tabKey={setTabKey}/>;
    } else if (tabKey === 'recordClass') {
      return <RecordClass />;
    } else if (tabKey === 'classRecord') {
      return <ClassRecordTable tabKey={setTabKey}/>;
    }
    return null;
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  if (tabKey === 'recordClass') {
    return <>{renderChildrenByTabKey()}</>
  } else {
    return (
      <PageContainer
        header={{title: ''}}
        tabList={tabList}
        tabActiveKey={tabKey}
        onTabChange={handleTabChange}
      >
        {renderChildrenByTabKey()}
      </PageContainer>
    );
  }

}


export default TeachingLogTab;

