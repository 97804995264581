import {
  Button,
  Card,
  DatePicker,
  Input,
  Form,
  InputNumber,
  Select,
  Row,
  message, Col, Radio, Tag, Table
} from 'antd';
import React, {useEffect, useState} from 'react';
import {PageContainer,ModalForm} from '@ant-design/pro-components';
import styles from './style.less';
import {
  addOrder,
  addStudent, classAllList, getFeeStandardByLessonId,
  lessonAllList, studentAllList,
  teacherAllList
} from "@/services/tmh/api";
import moment from "moment";
import {store} from "@/store";
import {useHistory} from "react-router-dom";
import {closeTabAction} from "@/assets/js/publicFunc";
import dayjs from "dayjs";

const FormItem = Form.Item;

const {Option} = Select;

const EnrollForm: React.FC = () => {
    const {employeeId = ''} = store.getState().storeData.userInfo || {};

    const history = useHistory();

    const [studentForm] = Form.useForm();

    const [form] = Form.useForm();

    const [lessonForm] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [studentList, setStudentList] = useState([]);

    const [formVisible, setFormVisible] = useState(false);

    const [lessonVisible, setLessonVisible] = useState(true);

    const [state, setState] = useState(false);

    const [introduce, setIntroduce] = useState(true);

    const [student, setStudent] = useState<TMH.Student>({});

    const [lesson, setLesson] = useState([]);

    const [lessonFormVisible, setLessonFormVisible] = useState(false);

  const [lessonName, setLessonName] = useState<string | undefined>('');

    const [lessonId, setLessonId] = useState<number>();

    const [labelName, setLabelName] = useState<string>();

    const [feeStandard, setFeeStandard] = useState([]);

    const [schoolClass, setSchoolClass] = useState([]);

    // 控制优惠输入框
    const [promotionHidden, setPromotionHidden] = useState(true);

    const [selectPromotionHidden, setSelectPromotionHidden] = useState(true);

    const [promotion, setPromotion] = useState<number>();

    const [feeId, setFeeId] = useState<number>();

    // 课程单价
    const [unitPrice, setUnitPrice] = useState<number>();

    // 记录的是 单价*数量(无优惠)
    const [totalPrice, setTotalPrice] = useState<number>();

    // 优惠价格
    const [promotionPrice, setPromotionPrice] = useState<number>(0);

    const [showPrice, setShowPrice] = useState<number>();

    const [inputNumberValue, setInputNumberValue] = useState<number>();

    const [lessonMode, setLessonMode] = useState<number>();

    const [discountOption, setDiscountOption] = useState([]);

    const [selectValue, setSelectValue] = useState<number>();

    const getFeeStandard = async (lessonId: number) => {
      try {
        const res = await getFeeStandardByLessonId({lessonId: lessonId});
        if (res.code === '0') {
          const temp: any = [];
          setUnitPrice(res.data.price);
          setTotalPrice(res.data.price);
          setShowPrice(res.data.price);
          setPromotionPrice(0);
          temp.push(<Option value={res.data.id} key={res.data.id}>{res.data.chargingStandard}</Option>);
          setFeeStandard(temp);
          setFeeId(res.data.id);
        }
      } catch (error) {
      }
    }

    const getClassAllList = async () => {
      try {
        const res = await classAllList();
        if (res.code === '0') {
          const temp: any = [];
          res.data.map(function (item: any) {
            temp.push(<option key={item.id}>{item.name}</option>);
          });
          setSchoolClass(temp);
        }
      } catch (error) {
      }
    }

    const getLessonSelect = async () => {
      try {
        const res = await lessonAllList({status: 1});
        if (res.code === '0') {
          const temp: any = [];
          res.data.map(function (item: any) {
            temp.push(<option value={item.id} data-lessonMode={item.lessonMode}>{item.name}</option>);
          });
          setLesson(temp);
        }
      } catch (error) {
      }
    }

    const getDiscountOption = () => {
      const temp: any = [];
      for (let i = 9.9; i > 0; i = parseFloat((i - 0.1).toFixed(10))) {
        temp.push(<option value={i}>
            {`${i}折`}
          </option>
        )
      }
      setDiscountOption(temp);
    }

    const getStudentListSelect = async () => {
      try {
        const res = await studentAllList();
        if (res.code === '0') {
          const temp: any = [];
          res.data.map(function (item: any) {
            temp.push(<option
              key={item.id}
              data-sex={item.sex}
              data-mobile={item.contactPhone}
              data-relation={item.contactRelation}
              data-courseNum={item.courseNum}
            >
              {item.name}
            </option>);
          });
          setStudentList(temp);
        }
      } catch (error) {
      }
    }

    const cancelSubmit = () => {
      closeTabAction(history, '/');
    };

    const [teacher, setTeacher] = useState([]);

    const getTeacherSelect = async () => {
      try {
        const res = await teacherAllList();
        if (res.code === '0') {
          const temp: any = [];
          res.data.map(function (item: any) {
            temp.push(
              <option key={item.id} value={item.id}>
                {`${item.name}`}
              </option>);
          });
          setTeacher(temp);
        }
      } catch (error) {
      }
    }

    useEffect(() => {
      getStudentListSelect().then();
      getDiscountOption();
      getTeacherSelect().then();
    }, [state]);

    const onFinish = async (values: { [key: string]: any }) => {
      let enrollList = [];
      enrollList.push(
        {
          lessonId: lessonId,
          lessonMode: lessonMode,
          teacherId: values.teacherId,
          classId: values.classId,
          num: values.num,
          lessonFeeStandardId: feeId,
        }
      )
      if (values.type === 1) {
        enrollList[0].promoteInfo = {
          type: values.type,
          decreasePrice: inputNumberValue
        }
      } else if (values.type === 2) {
        enrollList[0].promoteInfo = {
          type: values.type,
          discount: selectValue
        }
      } else if (values.type === 3) {
        enrollList[0].promoteInfo = {
          type: values.type,
          reduceHour: inputNumberValue
        }
      }
      values.enrollList = enrollList;
      values.tradeType = 1;
      console.log(values);
      setLoading(true);
      setTimeout(async () => {
        try {
          const data = await addOrder(values);
          if (data.code === '0') {
            message.success('添加成功！');
            closeTabAction(history, '/');
            return;
          }
        } catch (error) {
        }
      }, 500);
      setLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
      // eslint-disable-next-line no-console
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: {span: 4},
      wrapperCol: {span: 18},
    };

    const studentLayout = {
      labelCol: {span: 6},
      wrapperCol: {span: 18},
    };

    const layout1 = {
      labelCol: {span: 6},
      wrapperCol: {span: 16},
    };

    const layout2 = {
      labelCol: {span: 7},
      wrapperCol: {span: 17},
    };

    const tailLayout = {
      wrapperCol: {span: 16},
    };
    const data = [{}];

    const columns1 = [
      {
        title: '价格',
        width: '50%',
        render: () => {
          return (
            <>
              <Row>
                <Col span={9}>
                  <FormItem
                    label={'收费模式'}
                    initialValue={feeId}
                    {...layout2}
                  >
                    <Select value={feeId} style={{width: '100%'}}>
                      {feeStandard}
                    </Select>
                  </FormItem>
                </Col>
                <Col offset={1} span={1} style={{marginTop: '5px'}}>X</Col>
                <Col span={4}>
                  <FormItem
                    name={'num'}
                    initialValue={1}
                  >
                    <InputNumber min={1} defaultValue={1} value={1}
                                 onChange={(v) => {
                                   setTotalPrice(Number(v) * unitPrice);
                                   setShowPrice(Number(v) * unitPrice - promotionPrice);
                                 }
                                 }/>
                  </FormItem>
                </Col>
              </Row>
              {/*<Space size={'large'}>*/}
              {/*  <FormItem*/}
              {/*    label={'收费模式'}*/}
              {/*    initialValue={feeId}*/}
              {/*    {...layout2}*/}
              {/*  >*/}
              {/*    <Select value={feeId} style={{width: '100%'}}>*/}
              {/*      {feeStandard}*/}
              {/*    </Select>*/}
              {/*  </FormItem>*/}
              {/*  <FormItem*/}
              {/*    name={'num'}*/}
              {/*    initialValue={1}*/}
              {/*  >*/}
              {/*    <InputNumber min={1} defaultValue={1} value={1}*/}
              {/*                 onChange={(v) => {*/}
              {/*                   setTotalPrice(Number(v) * price);*/}
              {/*                   setShowPrice(Number(v) * price);*/}
              {/*                 }*/}
              {/*                 }/>*/}
              {/*  </FormItem>*/}
              {/*</Space>*/}
            </>
          )
        }
      },
      {
        title: '优惠',
        width: '50%',
        render: () => {
          return (
            <Row>
              <Col span={10}>
                <FormItem
                  name={'type'}
                  initialValue={-1}
                >
                  <Select defaultValue={-1}
                          onSelect={(e) => {
                            setInputNumberValue(undefined);
                            setSelectValue(undefined);
                            setShowPrice(totalPrice);
                            if (e === -1) {
                              setPromotionHidden(true);
                              setSelectPromotionHidden(true);
                            } else if (e === 1 || e === 3) {
                              setPromotionHidden(false);
                              setSelectPromotionHidden(true);
                            } else if (e === 2) {
                              setPromotionHidden(true);
                              setSelectPromotionHidden(false);
                            }
                            setPromotion(e);
                          }}
                  >
                    <Option value={-1}>暂无优惠</Option>
                    <Option value={1}>立减优惠</Option>
                    <Option value={2}>打折优惠</Option>
                    <Option value={3}>赠送课时</Option>
                  </Select>
                </FormItem>
              </Col>
              <Col span={14}>
                <InputNumber min={1}
                             style={{display: promotionHidden ? 'none' : '', width: '50%'}}
                             value={inputNumberValue}
                             placeholder={promotion === 1 ? '请输入立减金额（元）' : '请输入赠送课时'}
                             onChange={(v) => {
                               setInputNumberValue(v);
                               if (promotion === 1) {
                                 setPromotionPrice(v);
                                 setShowPrice(totalPrice - v);
                               }
                             }
                             }
                             onBlur={(v) => {
                               if (v.target.value === '') {
                                 setShowPrice(totalPrice);
                               } else {
                                 if (promotion != 3) {
                                   let temp = Math.floor(totalPrice - promotionPrice)
                                   setShowPrice(temp);
                                 }
                               }
                             }}
                />
                <Select
                  placeholder={'请选择折扣'}
                  style={{display: selectPromotionHidden ? 'none' : '', width: '50%'}}
                  value={selectValue}
                  onSelect={(v: number) => {
                    setSelectValue(v);
                    if (promotion === 2) {
                      let result = totalPrice - totalPrice * v / 10;
                      setPromotionPrice(result);
                      setShowPrice(totalPrice - result);
                    }
                  }}
                >
                  {discountOption}
                </Select>
              </Col>
            </Row>
          )
        }
      },
    ]

    // @ts-ignore
    return (
      <>
        <ModalForm
          form={studentForm}
          title={'添加学员'}
          width="680px"
          onVisibleChange={setFormVisible}
          visible={formVisible}
          onFinish={async (values) => {
            if (values.birthday) {
              values.birthday = values.birthday.substr(0, 10);
            }
            setTimeout(async () => {
              try {
                // 添加学员
                const data = await addStudent(values);
                if (data.code === '0') {
                  message.success('添加成功！');
                  setFormVisible(false);
                  studentForm.resetFields();
                  setState(!state);
                  return;
                }
                // 如果失败去设置用户错误信息
              } catch (error) {
              }
            }, 500);

          }}
        >
          <Row gutter={16} justify="space-around" align="middle">
            <Col span={10}>
              <Form.Item
                label={'学员姓名'}
                name="name"
                rules={[{required: true, message: '姓名不能为空'}]}
              >
                <Input placeholder="请输入学员姓名"/>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={'性别'}
                name="sex"
                initialValue={2}
              >
                <Radio.Group defaultValue={2}>
                  <Radio value={2}>
                    女
                  </Radio>
                  <Radio value={1}>
                    男
                  </Radio>
                  <Radio value={0}>
                    保密
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} justify="space-around" align="middle">
            <Col span={10}>
              <Form.Item label="联系人">
                <Input.Group compact>
                  <Form.Item
                    name="contactRelation"
                    noStyle
                    initialValue={'母亲'}
                  >
                    <Select defaultValue={'母亲'} style={{width: '30%'}}>
                      <Option value={'母亲'}>母亲</Option>
                      <Option value={'父亲'}>父亲</Option>
                      <Option value={'本人'}>本人</Option>
                      <Option value={'其他'}>其他</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={'contactPhone'}
                    noStyle
                    rules={[{required: true, message: '手机号不能为空'}]}
                  >
                    <Input style={{width: '70%'}} placeholder="请输入手机号"/>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={'生日'}
                name="birthday"
              >
                <DatePicker placeholder={'请选择学员生日'} style={{width: '100%'}}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16} justify="space-around" align="middle">
            <Col span={10}>
              <Form.Item
                label={'来源渠道'}
                name="channelId"
                initialValue={1}
              >
                <Select defaultValue={1}>
                  <Option value={1}>自然上门</Option>
                  <Option value={2}>教师推荐</Option>
                  <Option value={3}>老学员介绍</Option>
                  <Option value={4}>地推活动</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label={'介绍人'}
                name="introducer"
              >
                <Input placeholder="请输入介绍人"/>
              </Form.Item>
            </Col>
          </Row>
        </ModalForm>


        <ModalForm
          form={lessonForm}
          {...layout1}
          layout={'horizontal'}
          title={'选择课程'}
          width="450px"
          onVisibleChange={setLessonFormVisible}
          visible={lessonFormVisible}
          onFinish={async (values) => {
            setLessonFormVisible(false);
            setLessonVisible(false);
            if (labelName === '上课班级') {
              getClassAllList().then();
            } else {
              getTeacherSelect().then();
            }
          }}
        >
          <FormItem
            label={'课程名称'}
            name="studentId"
            rules={[{required: true, message: '请选择课程'}]}
          >
            <Select
              placeholder={'请选择课程'}
              showSearch
              onSelect={(v: number, e) => {
                setLessonName(e?.label as string);
                setLessonId(v);
                if (e['data-lessonMode'] === 1) {
                  setLessonMode(1);
                  setLabelName('上课教师')
                } else {
                  setLessonMode(2);
                  setLabelName('上课班级')
                }
                getFeeStandard(v).then();
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

              }>


              {/*<option><span style={{marginRight: '25px'}}>姓名</span>{'剩余课时'}</option>*/}
              {lesson}
            </Select>
          </FormItem>
        </ModalForm>


        <Form
          style={{marginTop: 8}}
          {...layout}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <PageContainer >
            <Card className={styles.card} bordered={true} title={"学员信息"}>
              <Row>
                <Col span={6}>
                  <Form.Item
                    label={'学员姓名'}
                    {...studentLayout}
                    name="studentId"
                    rules={[
                      {
                        required: true,
                        message: '学员姓名不能为空',
                      },
                    ]}
                  >
                    <Select
                      placeholder={'请选择学员'}
                      showSearch
                      style={{width: '80%'}}
                      onSelect={(v, e) => {
                        setStudent({
                          name: e.label as string,
                          sex: e['data-sex'],
                          mobile: e['data-mobile'],
                          relation: e['data-relation'],
                          courseNum: e['data-courseNum'],
                        })
                        setIntroduce(false);
                      }
                      }
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {/*<option><span style={{marginRight: '25px'}}>姓名</span>{'剩余课时'}</option>*/}
                      {studentList}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" onClick={() => {
                    setFormVisible(true);
                  }}> 添加学员 </Button>
                </Col>
              </Row>
              <Row hidden={introduce}>
                <Col span={24} style={{background: '#f2f8ff'}}>
                  <span style={{
                    fontSize: '18px',
                    fontWeight: 700,
                    margin: '8px',
                  }}>{student.name}</span>
                  <span style={{fontSize: '14px', marginRight: '8px',}}>{student.sex}</span>
                  <span style={{fontSize: '14px'}}>{student.mobile}（{student.relation}）</span>
                  <span style={{
                    display: 'inline-block',
                    background: '#d4dfe5',
                    width: '1px',
                    height: '24px',
                    verticalAlign: 'middle',
                    marginRight: '8px'
                  }}/>
                  <Tag color="green" style={{fontSize: '13px'}}>课</Tag>
                  <span style={{fontSize: '14px', margin: '6px'}}>在读课程：{student.courseNum}</span>

                </Col>
              </Row>
            </Card>
            <Card className={styles.card} bordered={true}
                  title={(
                    <>
                      报名信息
                      <Button type="primary" style={{marginLeft: '15px'}} onClick={() => {
                        getLessonSelect().then();
                        setLessonFormVisible(true);
                      }}> 选择课程
                      </Button>
                    </>
                  )}>


              <Row hidden={lessonVisible}>
                <Col span={8}>
                  <Form.Item
                    label={'课程名称'}
                  >
                    <Input value={lessonName} readOnly style={{width: '80%'}}/>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {labelName === '上课班级' ? (
                      <Form.Item
                        name={'classId'}
                        label={labelName}

                      >
                        <Select
                          style={{width: '60%'}}
                          placeholder={'请选择班级'}
                          showSearch
                          filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }>
                          {/*<option><span style={{marginRight: '25px'}}>姓名</span>{'剩余课时'}</option>*/}
                          {schoolClass}
                        </Select>

                      </Form.Item>

                    ) :
                    (
                      <Form.Item
                        name={'teacherId'}
                        label={labelName}
                      >
                        <Select
                          style={{width: '60%'}}
                          placeholder={'请选择教师'}
                          showSearch
                          filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }>
                          {teacher}
                        </Select>

                      </Form.Item>
                    )
                  }
                </Col>

              </Row>
              <Row hidden={lessonVisible}>
                <Col span={24}>
                  <Table
                    bordered
                    pagination={false}
                    columns={columns1}
                    dataSource={data}
                  />
                </Col>
              </Row>
              <Row hidden={lessonVisible}>
                <Col span={8}>
                  <FormItem label={'备注'} name={'remark'} style={{marginTop: 32}}>
                    <Input style={{width: '100%'}}/>
                  </FormItem>
                </Col>
              </Row>
            </Card>
            <Card className={styles.card} bordered={true} title={"支付信息"} hidden={lessonVisible}>
              <Row>
                <Col offset={20} span={6}>
                   <span style={{
                     fontSize: '18px',
                     fontWeight: 700,
                     margin: '8px',
                   }}>总计价格：{showPrice}元</span>
                </Col>
              </Row>
            </Card>
            <Card className={styles.card} bordered={true} title={'经办信息'}>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label={'经办日期'}
                    name={'processDate'}
                    initialValue={moment()}
                  >
                    <DatePicker defaultValue={moment()}/>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={'经办人'}
                    name={'processEmployeeId'}
                    initialValue={employeeId}
                  >
                    <Select
                      style={{width: '60%'}}
                      placeholder={'请选择经办人'}
                      defaultValue={employeeId}
                      showSearch
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {teacher}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <FormItem {...tailLayout} style={{marginTop: 32}}>
                <Button type='default' onClick={cancelSubmit}>
                  取消
                </Button>
                <Button type="primary" htmlType="submit" style={{marginLeft: 24}} loading={loading}>
                  提交
                </Button>
              </FormItem>
            </Card>
          </PageContainer>
        </Form>
      </>
    );
  }
;
export default EnrollForm;
