import * as React from 'react';
import {useState} from "react";
import ScheduleListTable from "@/components/eduCenter/schedule";
import {PageContainer} from "@ant-design/pro-components";
import {withRouter} from "react-router-dom";
import CalendarListTable from "@/components/eduCenter/schedule/calendarList";
import ClassCalendar from "@/components/eduCenter/schedule/calendar";

const ScheduleTab: React.FC = () => {

  const [tabKey, setTabKey] = useState<string>('tab1');

  const tabList = [
    {
      key: 'tab1',
      tab: '课程表',
    },
    {
      key: 'tab2',
      tab: '排课规则列表',
    },
    {
      key: 'tab3',
      tab: '日程列表',
    },
  ];

  const renderChildrenByTabKey = () => {
    if (tabKey === 'tab1') {
      return <ClassCalendar/>
    } else if (tabKey === 'tab2') {
      return <ScheduleListTable/>;
    } else if (tabKey === 'tab3') {
      return <CalendarListTable/>;
    }
    return null;
  }

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  return (
    <PageContainer
      tabList={tabList}
      tabActiveKey={tabKey}
      onTabChange={handleTabChange}
    >
      {renderChildrenByTabKey()}
    </PageContainer>
  );
}

export default withRouter(ScheduleTab);
