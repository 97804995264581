import * as React from 'react';
import {PageContainer} from "@ant-design/pro-components";
import {useState} from "react";
import TextbookStorageTable from "@/components/inner/textBookMisc/storage";
import TextbookTable from "@/components/inner/textBookMisc/textbook";
import MiscTable from "@/components/inner/textBookMisc/misc";

const TextbookMiscTab: React.FC = () => {

  const [tabKey, setTabKey] = useState<string>('storage');

  const [formVisible, setFormVisible] = useState<boolean>(false);

  const tabList = [
    {
      key: 'storage',
      tab: '库存',
    },
    {
      key: 'textbook',
      tab: '教材设置',
    },
    {
      key: 'misc',
      tab: '杂费设置',
    },
  ];

  const renderChildrenByTabKey = () => {
    if (tabKey === 'storage') {
      return <TextbookStorageTable handleIsVisible={setFormVisible}/>;
    } else if (tabKey === 'textbook') {
      return <TextbookTable handleIsVisible={setFormVisible}/>;
    } else if (tabKey === 'misc') {
      return <MiscTable handleIsVisible={setFormVisible}/>;
    }
    return null;
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  return (
    <PageContainer
      tabList={tabList}
      tabActiveKey={tabKey}
      onTabChange={handleTabChange}
    >
      {renderChildrenByTabKey()}
    </PageContainer>
  );

}


export default TextbookMiscTab;

