import * as React from "react";
import {ActionType, ProColumns, ModalForm, ProTable} from "@ant-design/pro-components";
import {addLessonCategory, lessonCategoryList} from "@/services/tmh/api";
import {Button, Form, Input, message} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useRef, useState} from "react";

const LessonCategoryTable: React.FC = () => {

  const [formVisible, setFormVisible] = useState(false);

  const autoReLoad = useRef<ActionType>();

  const [form] = Form.useForm();

  const fieldLabels = {
    name: '课程类别',
  };

  const columns: ProColumns[] = [
    {
      title: '编号',
      dataIndex: 'id',
      valueType: 'text',
      search: false
    },
    {
      title: '课程类别',
      dataIndex: 'name',
      valueType: 'text',
    },
  ]


  return (
    <>
      <ProTable columns={columns}
                request={lessonCategoryList}
                key='category'
                actionRef={autoReLoad}
                toolBarRender={() => [
                  <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setFormVisible(true)
                  }}>
                    新建
                  </Button>
                ]}/>
      <ModalForm
        form={form}
        title={'添加课程类别'}
        layout={'horizontal'}
        width="400px"
        onVisibleChange={setFormVisible}
        visible={formVisible}
        onFinish={async (values) => {
          setTimeout(async () => {
            try {
              const data = await addLessonCategory(values);
              if (data.code === '0') {
                message.success('添加成功！');
                setFormVisible(false);
                if (autoReLoad.current) {
                  autoReLoad.current.reload();
                }
                form.resetFields();
                return;
              }
              // 如果失败去设置用户错误信息
            } catch (error) {
            }
          }, 500);
        }}
      >
        <Form.Item
          label={fieldLabels.name}
          name="name"
          rules={[{required: true, message: '课程类别不能为空'}]}
        >
          <Input placeholder="请输入课程类别"/>
        </Form.Item>
      </ModalForm>
    </>

  );

}

export default LessonCategoryTable;
