import React from 'react';
import { DefaultFooter } from '@ant-design/pro-components';

export default () => (
  <DefaultFooter
    copyright="2023 三音家"
    links={[
      {
        key: '',
        title: '',
        href: '',
        blankTarget: true,
      },
      {
        key: '',
        title: '',
        href: '',
        blankTarget: true,
      },
      {
        key: '',
        title: '',
        href: '',
        blankTarget: true,
      },
    ]}
  />
);
