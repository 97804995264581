import {
  Button,
  Card,
  DatePicker,
  Input,
  Form,
  InputNumber,
  Select,
  Row,
  message
} from 'antd';
import React, {useEffect, useState} from 'react';
import {PageContainer} from '@ant-design/pro-components';
import styles from './style.less';
import {
  addClass,
  lessonAllList,
  teacherAllList
} from "@/services/tmh/api";
import {closeTabAction} from "@/assets/js/publicFunc";
import {useHistory} from "react-router-dom";

const FormItem = Form.Item;
const {Option} = Select;

const ClassForm: React.FC = () => {

    const [form] = Form.useForm();

    const history: CommonObjectType = useHistory();

    const [loading, setLoading] = useState(false);

    const cancelSubmit = () => {
      closeTabAction(history, '/eduCenter/class');
    };

    const [lesson, setLesson] = useState([]);

    const getLessonSelect = async () => {
      try {
        const res = await lessonAllList({
          lessonMode: 2,
          status: 1,
        });
        if (res.code === '0') {
          const temp: any = [];
          res.data.map(function (item: any) {
            temp.push(<option key={item.id}>{item.name}</option>);
          });
          setLesson(temp);
        }
      } catch (error) {
      }
    }

    const [teacher, setTeacher] = useState([]);

    const getTeacherSelect = async () => {
      try {
        const res = await teacherAllList();
        if (res.code === '0') {
          const temp: any = [];
          res.data.map(function (item: any) {
            // if (item.sex === '男') {
            //   temp.push(
            //     <option key={item.id}>
            //       <ManOutlined twoToneColor="#eb2f96"/>{`  ${item.name}  ${item.mobile}`}
            //     </option>);
            // } else if (item.sex === '女') {
            //   temp.push(
            //     <option key={item.id}>
            //       <WomanOutlined twoToneColor="#eb2f96"/>{`  ${item.name}  ${item.mobile}`}
            //     </option>);
            // } else {
            //   temp.push(
            //     <option key={item.id}>
            //       {`${item.name}  ${item.mobile}`}
            //     </option>);
            // }
            temp.push(
              <option key={item.id}>
                {`${item.name}`}
              </option>);
          });
          setTeacher(temp);
        }
      } catch (error) {
      }
    }

    useEffect(() => {
      getLessonSelect().then();
      getTeacherSelect().then();
    }, []);

    const onFinish = async (values: { [key: string]: any }) => {
      values.lessonMode = 2;
      setLoading(true);
      setTimeout(async () => {
        try {
          const data = await addClass(values);
          if (data.code === '0') {
            message.success('添加成功！');
            closeTabAction(history, '/eduCenter/class');
            return;
          }
        } catch (error) {
        }
      }, 500);
      setLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
      // eslint-disable-next-line no-console
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: {span: 4},
      wrapperCol: {span: 10},
    };

    const tailLayout = {
      wrapperCol: {offset: 4, span: 16},
    };

    return (
      <Form
        style={{marginTop: 8}}
        {...layout}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <PageContainer>
          <Card className={styles.card} bordered={true} title={"班级信息"}>
            <FormItem
              label={'班级名称'}
              name="name"
              rules={[
                {
                  required: true,
                  message: '班级名称不能为空',
                },
              ]}
            >
              <Input placeholder={'请输入班级名称'} style={{width: '80%'}}/>
            </FormItem>
            <FormItem
              label={'选择课程'}
              name="lessonId"
              rules={[
                {
                  required: true,
                  message: '课程不能为空',
                },
              ]}
            >
              <Select
                placeholder={'请选择课程'}
                showSearch
                style={{width: '80%'}}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {lesson}
              </Select>
            </FormItem>
            <Form.Item
              label={'满班人数'}
              name="capacity"
              initialValue={1}
            >
              <InputNumber min={1} defaultValue={1}/>
            </Form.Item>
            <Form.Item
              label={'每次上课'}
            >
              <Row
                style={{marginBottom: '10px'}}>
                <FormItem
                  noStyle
                  name="studentReduceHour"
                  initialValue={1}
                >
                  <Input addonBefore={'学生扣除'} type={'number'} min={1} defaultValue={1} addonAfter={'课时'}
                         style={{width: '50%'}}/>
                </FormItem>
              </Row>
              <Row>
                <FormItem
                  noStyle
                  name="teacherAddHour"
                  initialValue={1}
                >
                  <Input addonBefore={'教师获得'} type={'number'} min={1} defaultValue={1} addonAfter={'课时'}
                         style={{width: '50%'}}/>
                </FormItem>
              </Row>
            </Form.Item>
            <Form.Item
              label={'招生状态'}
              name="status"
              initialValue={1}
            >
              <Select defaultValue={1} style={{width: '80%'}}>
                <Option value={1}>开放招生</Option>
                <Option value={2}>满班后停止</Option>
                <Option value={3}>停止</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={'开班日期'}
              name="openDate"
              rules={[
                {
                  required: true,
                  message: '开班日期不能为空',
                },
              ]}
            >
              <DatePicker placeholder={'请选择开班日期'} style={{width: '80%'}}/>
            </Form.Item>
            <Form.Item
              label={'结班日期'}
              name="closeDate"
            >
              <DatePicker placeholder={'请选择结班日期'} style={{width: '80%'}}/>
            </Form.Item>
          </Card>
          <Card className={styles.card} bordered={true} title={"其他信息"}>
            <FormItem label={'班主任'} name={'teacherId'}
                      rules={[
                        {
                          required: true,
                          message: '班主任不能为空',
                        },
                      ]}
            >
              <Select
                placeholder={'请选择班主任'}
                showSearch
                style={{width: '80%'}}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {teacher}
              </Select>
            </FormItem>
            <FormItem label={'备注'} name={'remark'}>
              <Input.TextArea style={{width: '80%'}}/>
            </FormItem>
            <FormItem {...tailLayout} style={{marginTop: 32}}>
              <Button type='default' onClick={cancelSubmit}>
                取消
              </Button>
              <Button type="primary" htmlType="submit" style={{marginLeft: 24}} loading={loading}>
                提交
              </Button>
            </FormItem>
          </Card>
        </PageContainer>
      </Form>
    );
  }
;
export default ClassForm;
