import * as React from 'react';
import {PageContainer} from "@ant-design/pro-components";
import {useState} from "react";
import LessonTable from "@/components/eduCenter/lesson";
import LessonCategoryTable from "@/components/eduCenter/lesson/lessonCategory";
import SubjectTable from "@/components/eduCenter/lesson/subject";

const LessonTab: React.FC = () => {

  const [tabKey, setTabKey] = useState<string>('lesson');


  const tabList = [
    {
      key: 'lesson',
      tab: '课程管理',
    },
    {
      key: 'category',
      tab: '课程类别',
    },
    {
      key: 'subject',
      tab: '科目设置',
    },
  ];

  const renderChildrenByTabKey = () => {
    if (tabKey === 'lesson') {
      return <LessonTable/>;
    } else if (tabKey === 'category') {
      return <LessonCategoryTable/>;
    } else if (tabKey === 'subject') {
      return <SubjectTable/>;
    }
    return null;
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  return (
    <PageContainer
      tabList={tabList}
      tabActiveKey={tabKey}
      onTabChange={handleTabChange}
    >
      {renderChildrenByTabKey()}
    </PageContainer>
  );

}


export default LessonTab;

