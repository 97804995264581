import React, {useEffect, useState} from "react";
import {Tree} from "antd";
import {permissionTreeList} from "@/services/tmh/api";

interface DataNode {
  title: string;
  key: number;
  isLeaf?: boolean;
  children?: DataNode[];
}

const RoleTree: React.FC<{}> = () => {

  const initTreeDate: DataNode[] = [];

  const [treeData, setTreeData] = useState(initTreeDate);

  const [success, isSuccess] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    permissionTreeList().then(
      (res) => {
        if (!res.success) {
          isSuccess(false);
        } else {
          if (res.data) {
            let result = convertData(res.data);
            console.log(result)
            setTreeData(result);
          }
        }
      }
    );
  }

  function convertData(data: any[]): DataNode[] {
    let dataNodes: DataNode[] = [];
    data.forEach(function (item) {
      let dataNode: DataNode = {key: 0, title: ""};
      item.name && (dataNode.title = item.name);
      item.id && (dataNode.key = item.id);
      if (item.permModuleList && Array.isArray(item.permModuleList)) {
        dataNode.children = convertData(item.permModuleList);
      }
      dataNodes.push(dataNode);
    })
    return dataNodes;
  }

  return <Tree
    checkable
    treeData={treeData}

  />

};

export default RoleTree;
