import * as React from "react";
import {ProTable, ActionType, ProColumns, ModalForm} from "@ant-design/pro-components";
import {
  addTextbook,
  lessonCategoryAllList,
  textbookList
} from "@/services/tmh/api";
import {Button, Form, Input, InputNumber, message, Radio, Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import FormItem from "antd/es/form/FormItem";

interface Props {
  handleIsVisible: Function;
}

const TextbookTable: React.FC<Props> = (props) => {

  const [lessonCategory, setLessonCategory] = useState([]);

  const getLessonSelect = async () => {
    try {
      const res = await lessonCategoryAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setLessonCategory(temp);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getLessonSelect().then();
  }, []);

  const [formVisible, setFormVisible] = useState(false);

  const autoReLoad = useRef<ActionType>();

  const [form] = Form.useForm();

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
  };

  const fieldLabels = {
    name: '教材名称',
    lessonCategoryId: '课程类别',
    purchasePrice: '进货价',
    salePrice: '售价',
    status: '状态',
  };

  const columns: ProColumns[] = [
    {
      title: '教材名称',
      dataIndex: 'name',
      valueType: 'text',
    },
    {
      title: '进价',
      dataIndex: 'purchasePrice',
      valueType: 'money',
      search: false
    },
    {
      title: '售价',
      dataIndex: 'salePrice',
      valueType: 'money',
      search: false
    },
    {
      title: '状态',
      dataIndex: 'status',
      valueType: 'select',
      valueEnum: {
        1: {text: '正常'},
        0: {text: '下架'},
      }
    },

    {
      title: '课程类别',
      dataIndex: 'lessonCategoryName',
      valueType: 'text',
      search: false
    },
    {
      title: '课程类别',
      dataIndex: 'lessonCategoryId',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请输入课程类别'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            <option value={-1}>通用教材/杂费</option>
            {lessonCategory}
          </Select>)
      },
    },
  ]

  return (
    <>
      <ProTable columns={columns}
                request={textbookList}
                key='category'
                actionRef={autoReLoad}
                toolBarRender={() => [
                  <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
                    setFormVisible(true)
                  }}>
                    添加教材
                  </Button>
                ]}/>
      <ModalForm
        form={form}
        {...layout}
        title={'添加教材'}
        layout={'horizontal'}
        width="450px"
        onVisibleChange={setFormVisible}
        visible={formVisible}
        onFinish={async (values) => {
          values.type = '教材';
          setTimeout(async () => {
            try {
              const data = await addTextbook(values);
              if (data.code === '0') {
                message.success('添加成功！');
                setFormVisible(false);
                if (autoReLoad.current) {
                  autoReLoad.current.reload();
                }
                form.resetFields();
                return;
              }
              // 如果失败去设置用户错误信息
            } catch (error) {
            }
          }, 400);
        }}
      >
        <FormItem
          label={fieldLabels.name}
          name="name"
          rules={[{required: true, message: '教材名称不能为空'}]}
        >
          <Input placeholder="请输入课程类别"/>
        </FormItem>
        <FormItem
          label={fieldLabels.lessonCategoryId}
          name="lessonCategoryId"
          rules={[{required: true, message: '课程类别不能为空'}]}
          initialValue={-1}
        >
          <Select
            defaultValue={-1}
            placeholder={'请选择课程类别'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            <option value={-1}>通用教材</option>
            {lessonCategory}
          </Select>
        </FormItem>
        <FormItem
          label={fieldLabels.purchasePrice}
          name="purchasePrice"
        >
          <InputNumber min={1} style={{width: '100%'}}/>
        </FormItem>
        <FormItem
          label={fieldLabels.salePrice}
          name="salePrice"
          rules={[{required: true, message: '售价不能为空'}]}
        >
          <InputNumber min={1} style={{width: '100%'}}/>
        </FormItem>
        <FormItem
          label={fieldLabels.status}
          name="status"
          initialValue={1}
        >
          <Radio.Group defaultValue={1}>
            <Radio value={1}>
              正常
            </Radio>
            <Radio value={0}>
              下架
            </Radio>
          </Radio.Group>
        </FormItem>
      </ModalForm>
    </>

  );

}

export default TextbookTable;
