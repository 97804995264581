import {ProColumns, ProTable} from "@ant-design/pro-components";
import {lessonCategoryAllList, oneToOneList, teacherAllList} from "@/services/tmh/api";
import * as React from "react";
import {Select} from "antd";
import {useEffect, useState} from "react";

const OneToOneTable: React.FC = () => {

  const [lessonCategory, setLessonCategory] = useState([]);

  const getLessonSelect = async () => {
    try {
      const res = await lessonCategoryAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setLessonCategory(temp);
      }
    } catch (error) {
    }
  }

  const [teacher, setTeacher] = useState([]);

  const getTeacherSelect = async () => {
    try {
      const res = await teacherAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(
            <option key={item.id}>
              {`${item.name}`}
            </option>);
        });
        setTeacher(temp);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getLessonSelect().then();
    getTeacherSelect().then();
  }, []);

  const columns: ProColumns[] = [
    {
      title: '学员姓名',
      dataIndex: 'studentName',
      valueType: 'text',
    },
    {
      title: '关系',
      dataIndex: 'contactRelation',
      valueType: 'select',
      search: false
    },
    {
      title: '联系方式',
      dataIndex: 'contactPhone',
      valueType: 'text',
      search: false
    },
    {
      title: '教师',
      dataIndex: 'teacherName',
      valueType: 'text',
      search: false
    },
    {
      title: '收费模式',
      dataIndex: 'feeMode',
      valueEnum: {
        1: {text: '按课时'},
        2: {text: '按时间段'},
        3: {text: '按期'},
      }
    },
    {
      title: '教师',
      dataIndex: 'teacherId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择班主任'}
            showSearch
            style={{width: '80%'}}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {teacher}
          </Select>)
      },
    },

    {
      title: '课程名称',
      dataIndex: 'lessonName',
      valueType: 'text',
      search: false,
    },
    {
      title: '课程类别',
      dataIndex: 'categoryName',
      valueType: 'text',
      search: false
    },
    {
      title: '课程类别',
      dataIndex: 'categoryId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择课程类别'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {lessonCategory}
          </Select>)
      },
    },
    {
      title: '开班日期',
      key: 'dateRange',
      valueType: 'dateRange',
      search: {
        transform: (value: any) => ({openDateStart: value[0] + " 00:00:00", openDateEnd: value[1] + " 23:59:59"}),
      },
      hideInTable: true,
    },
    {
      title: '收费标准',
      dataIndex: 'chargingStandard',
      search: false
    },
    {
      title: '开班日期',
      dataIndex: 'openDate',
      search: false
    },
    {
      title: '已用课时',
      dataIndex: 'usedHour',
      search: false,
      renderText: (val: string) => {
        if (typeof val === 'undefined') {
          return '-';
        }
        return `${val}课时`;
      }
    },
    {
      title: '剩余课时',
      dataIndex: 'remainingHour',
      search: false,
      renderText: (val: string) => {
        if (typeof val === 'undefined') {
          return '-';
        }
        return `${val}课时`;
      }
    },
  ]

  return (
    <ProTable columns={columns}
              request={oneToOneList}
              key='oneToOne'
              search={{
                defaultCollapsed: false
              }}
    />
  );

}

export default OneToOneTable;
