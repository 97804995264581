import * as React from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Table, TimePicker
} from "antd";
import {FooterToolbar, PageContainer} from "@ant-design/pro-components";
import {forwardRef, useEffect, useState} from "react";
import {
  classScheduleRecordDetail,
  classStudentList,
  doRecord, editClassScheduleRecord,
  getClassDetail,
  teacherAllList
} from "@/services/tmh/api";
import moment from "moment";
import {closeTabAction, getQuery} from "@/assets/js/publicFunc";
import {useHistory} from "react-router-dom";


/**
 * 记上课
 * @param props
 * @constructor
 */
const RecordClass = forwardRef(() => {

  const [teacher, setTeacher] = useState([]);

  const [loading, setLoading] = useState(false);

  const [studentList, setStudentList] = useState([]);

  const [classDetail, setClassDetail] = useState<any>({});

  const [row, setRow] = useState<any>({});

  const [timePeriod] = useState();

  const [form] = Form.useForm();

  const timeFormat = 'HH:mm';

  const history: CommonObjectType = useHistory();

  const {id} = getQuery();

  const {classId} = getQuery();

  const {editOnly} = getQuery();

  const {isScheduleView} = getQuery();

  const {readOnlyFlag} = getQuery();

  // 编辑状态
  useEffect(() => {
    if (classId) {
      queryClassDetail().then();
      getStudentList(classId).then();
    }
    if (id) {
      queryDetail().then();
    }
    getTeacherSelect().then();
  }, [id]);

  const queryDetail = async () => {
    try {
      const res = await classScheduleRecordDetail({id});
      if (res.code === '0') {
        setRow(res.data);
        getStudentList(res.data.classId).then();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const queryClassDetail = async () => {
    try {
      const res = await getClassDetail({id: classId});
      if (res.code === '0') {
        setClassDetail(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const onFinish = async (values: { [key: string]: any }) => {
    values.dateForClass = values.dateForClass.format('YYYY-MM-DD');
    if (values.timePeriod === undefined) {
      values.timePeriod = timePeriod;
    } else {
      values.timePeriod = `${values.timePeriod[0].format('HH:mm')}-${values.timePeriod[1].format('HH:mm')}`;
    }
    if (row.id) {
      values.classId = row.classId;
    } else {
      values.classId = classDetail.id;
    }
    if (!editOnly) {
      values.classScheduleRecordId = row.id;
      const studentInfoList = [];
      for (let i = 0; i < index; i++) {
        studentInfoList.push(
          {
            studentId: values[`studentInfoList[${i}].studentId`],
            studentReduceHour: values[`studentInfoList[${i}].studentReduceHour`],
            status: values[`studentInfoList[${i}].status`],
            isPush: values[`studentInfoList[${i}].isPush`]
          }
        )
        delete values[`studentInfoList[${i}].studentId`];
        delete values[`studentInfoList[${i}].studentReduceHour`];
        delete values[`studentInfoList[${i}].status`];
        delete values[`studentInfoList[${i}].isPush`];
      }
      values.studentInfoList = studentInfoList;
    } else {
      values.id = row.id;
    }

    setLoading(true);
    setTimeout(async () => {
      try {
        let data;
        if (editOnly) {
          data = await editClassScheduleRecord(values);
        } else {
          data = await doRecord(values);
        }
        if (data.code === '0') {
          message.success('操作成功！');
          if (isScheduleView) {
            const broadcastChannel = new BroadcastChannel('printChannel');
            broadcastChannel.postMessage('打印一行字');
            broadcastChannel.close();
            closeTabAction(history, '/eduCenter/schedule');
          } else {
            closeTabAction(history, '/eduCenter/teachingLog');
          }
          return;
        }
      } catch (error) {
      }
    }, 500);
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const cancelSubmit = () => {
    // props.tabKey('today');
    if (isScheduleView) {
      const broadcastChannel = new BroadcastChannel('printChannel');
      broadcastChannel.postMessage('打印一行字');
      broadcastChannel.close();
      closeTabAction(history, '/eduCenter/schedule');
    } else {
      closeTabAction(history, '/eduCenter/teachingLog');
    }
  };

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  };

  const remarkLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
  };

  const getStudentList = async (id) => {
    try {
      const res = await classStudentList(id);
      if (res.code === '0') {
        setStudentList(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  let index = 0;

  const columns = [
    {
      title: '学员编号',
      dataIndex: 'id',
      render: (_, record) => {
        return (
          <Form.Item
            noStyle
            name={`studentInfoList[${index}].studentId`}
            initialValue={record.id}
          >
            {record.id}
          </Form.Item>
        )
      }
    },
    {
      title: '学员姓名',
      dataIndex: 'name',
    },
    {
      title: '已用课时',
      dataIndex: 'usedHour',
    },
    {
      title: '剩余课时',
      dataIndex: 'remainingHour',
    },
    {
      title: '本次扣课时',
      render: () => {
        return (
          id ? (
              <Form.Item
                name={`studentInfoList[${index}].studentReduceHour`}
                noStyle
                initialValue={row.studentReduceHour}
              >
                <InputNumber
                  disabled={readOnlyFlag === '1'}
                  defaultValue={row.studentReduceHour}
                  min={0}/>
              </Form.Item>
            )
            :
            (
              <Form.Item
                name={`studentInfoList[${index}].studentReduceHour`}
                noStyle
                initialValue={classDetail.studentReduceHour}
              >
                <InputNumber
                  disabled={readOnlyFlag === '1'}
                  defaultValue={classDetail.studentReduceHour}
                  min={0}/>
              </Form.Item>
            )
        )
      }
    },
    {
      title: '到课状态',
      render: () => {
        return (
          <Form.Item
            name={`studentInfoList[${index}].status`}
            noStyle
            initialValue={1}
          >
            <Radio.Group disabled={readOnlyFlag === '1'}
                         defaultValue={1}>
              <Radio.Button value={1}>到课</Radio.Button>
              <Radio.Button value={2}>请假</Radio.Button>
              <Radio.Button value={3}>缺课</Radio.Button>
            </Radio.Group>
          </Form.Item>
        )
      }
    },
    {
      title: '公众号提醒',
      render: () => {
        return (
          <Form.Item
            name={`studentInfoList[${index++}].isPush`}
            noStyle
            valuePropName={'checked'}
            initialValue={true}
          >
            <Checkbox disabled={readOnlyFlag === '1'}
                      defaultChecked={true}/>
          </Form.Item>
        )
      }
    },
  ]

  const getTeacherSelect = async () => {
    try {
      const res = await teacherAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(
            <option key={item.id} value={item.id}>
              {`${item.name}`}
            </option>);
        });
        setTeacher(temp);
      }
    } catch (error) {
    }
  }

  return (
    <>
      <Form
        style={{marginTop: 8}}
        form={form}
        {...layout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <PageContainer>
          <Card
            title={'上课信息'}
          >
            <Row gutter={16}>
              <Col span={6}>
                {
                  id ? (
                    <Form.Item
                      label={'班级名称'}
                    >
                      {row.className}
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label={'班级名称'}
                    >
                      {classDetail.name}
                    </Form.Item>
                  )
                }
              </Col>
              <Col span={6}>

                {
                  id ? (
                    <Form.Item
                      label={'课程名称'}
                    >
                      {row.lessonName}
                    </Form.Item>
                  ) : (

                    <Form.Item
                      label={'课程名称'}
                    >
                      {classDetail.lessonName}
                    </Form.Item>
                  )
                }
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                {
                  row.id && (
                    <Form.Item
                      label={'上课日期'}
                      name={'dateForClass'}
                      rules={[{required: true, message: '上课日期不能为空'}]}
                      initialValue={moment(row?.dateForClass)}
                    >
                      <DatePicker disabled={readOnlyFlag === '1'}
                                  defaultValue={moment(row?.dateForClass)}
                                  style={{width: '80%'}}/>
                    </Form.Item>
                  )
                }
                {
                  classId && (
                    <Form.Item
                      label={'上课日期'}
                      name={'dateForClass'}
                      rules={[{required: true, message: '上课日期不能为空'}]}
                    >
                      <DatePicker disabled={readOnlyFlag === '1'}
                                  style={{width: '80%'}}/>
                    </Form.Item>
                  )
                }
              </Col>
              <Col span={6}>
                {
                  row.id && (
                    <Form.Item
                      label={'上课时段'}
                      name={'timePeriod'}
                      rules={[{required: true, message: '上课时段不能为空'}]}
                      initialValue={[moment(row.timePeriod.split('-')[0], timeFormat), moment(row.timePeriod.split('-')[1], timeFormat)]}
                    >
                      <TimePicker.RangePicker
                        disabled={readOnlyFlag === '1'}
                        style={{width: '80%'}}
                        defaultValue={[moment(row.timePeriod.split('-')[0], timeFormat), moment(row.timePeriod.split('-')[1], timeFormat)]}
                        format={timeFormat}/>
                    </Form.Item>
                  )
                }
                {
                  classId && (
                    <Form.Item
                      label={'上课时段'}
                      name={'timePeriod'}
                      rules={[{required: true, message: '上课时段不能为空'}]}
                    >
                      <TimePicker.RangePicker
                        style={{width: '80%'}}
                        format={timeFormat}/>
                    </Form.Item>
                  )
                }
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                {
                  row.id && (
                    <Form.Item
                      label={'上课教师'}
                      name={'teacherId'}
                      rules={[{required: true, message: '上课教师不能为空'}]}
                      initialValue={row.teacherId}
                    >
                      <Select
                        disabled={readOnlyFlag === '1'}
                        placeholder={'请选择教师'}
                        showSearch
                        defaultValue={row.teacherId}
                        style={{width: '80%'}}
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {teacher}
                      </Select>
                    </Form.Item>
                  )
                }
                {
                  classId && (
                    <Form.Item
                      label={'上课教师'}
                      name={'teacherId'}
                      rules={[{required: true, message: '上课教师不能为空'}]}
                      initialValue={classDetail.teacherId}
                    >
                      <Select
                        disabled={readOnlyFlag === '1'}
                        placeholder={'请选择教师'}
                        showSearch
                        defaultValue={classDetail.teacherId}
                        style={{width: '80%'}}
                        filterOption={(input, option: any) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {teacher}
                      </Select>
                    </Form.Item>
                  )
                }
              </Col>
              <Col span={6}>
                {
                  row.id && (
                    <Form.Item
                      label={'教师课时'}
                      name={'teacherAddHour'}
                      initialValue={row.teacherAddHour}
                      rules={[{required: true, message: '教师课时不能为空'}]}
                    >
                      <InputNumber
                        disabled={readOnlyFlag === '1'}
                        defaultValue={row.teacherAddHour}
                        min={0}
                        style={{width: '80%'}}/>
                    </Form.Item>
                  )
                }
                {
                  classId && (
                    <Form.Item
                      label={'教师课时'}
                      name={'teacherAddHour'}
                      initialValue={1}
                      rules={[{required: true, message: '教师课时不能为空'}]}
                    >
                      <InputNumber
                        disabled={readOnlyFlag === '1'}
                        defaultValue={1}
                        min={0}
                        style={{width: '80%'}}/>
                    </Form.Item>
                  )
                }
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                {
                  row.id && (
                    <Form.Item {...remarkLayout} label={'备注'} name={'remark'}  initialValue={row.remark}>
                      <Input.TextArea
                        disabled={readOnlyFlag === '1'}
                        defaultValue={row.remark}
                        style={{width: '100%'}} />
                    </Form.Item>
                  )
                }
                {
                  classId && (
                    <Form.Item {...remarkLayout} label={'备注'} name={'remark'}>
                      <Input.TextArea
                        disabled={readOnlyFlag === '1'}
                        style={{width: '100%'}} />
                    </Form.Item>
                  )
                }
              </Col>
            </Row>
          </Card>
          {
            !editOnly && (
              <Card
                title={'学员记上课'}
                style={{marginTop: '20px'}}
              >
                <Table
                  bordered
                  pagination={false}
                  columns={columns}
                  dataSource={studentList}
                />
              </Card>
            )
          }
        </PageContainer>
        <FooterToolbar>
          <Button type="default" onClick={cancelSubmit}>
            关闭
          </Button>
          {
            readOnlyFlag !== '1' && (
              <Button type="primary" htmlType="submit" style={{marginLeft: 10}} loading={loading}>
                保存
              </Button>
            )
          }
        </FooterToolbar>
      </Form>
    </>
  );

});

export default RecordClass;
