export const Constants = {
  baseUrl: 'http://1.117.230.194:8080/tmh',
};


export const HrStatus = {
  1: '正式员工',
  2: '试用期员工',
  3: '离职员工',
  4: '解雇员工',
  5: '兼职员工',
  6: '面试期',
  7: '培训期',
  8: '停薪留职',
  9: '转正失败',
}

