/* eslint-disable */
// @ts-ignore
import {getRequest, postRequest} from '@/utils/request';
import {Constants} from "@/utils/constants";


/** 展示员工列表 POST /employee/pageList */
export async function employeeList(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/employee/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 展示学员列表 POST /api/rule */
export async function studentList(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/student/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 查询学员总数量 POST /api/rule */
export async function getStudentCount(
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/student/count', {
    ...(options || {}),
  });
}

/** 添加学员  */
export async function addStudent(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/student/add', {
    data: params,
    ...(options || {}),
  });
}

/** 删除学员  */
export async function removeStudent(
  params: {
    id: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/student/remove/' + params.id, {
    data: params,
    ...(options || {}),
  });
}


/** 学员开关设置 **/
export async function setStudentSwitch(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/student/setSwitch', {
    data: params,
    ...(options || {}),
  });
}


/** 添加员工  */
export async function addEmployee(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/employee/register', {
    data: params,
    ...(options || {}),
  });
}


/** 获取权限树 */
export async function permissionTreeList(
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/permissionModule/treeList', {
    ...(options || {}),
  });
}

/** 查询课程类别列表 */
export async function lessonCategoryList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/lessonCategory/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 查询课程类别 */
export async function addLessonCategory(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/lessonCategory/add', {
    data: params,
    ...(options || {}),
  });
}

/** 查询科目列表 */
export async function subjectList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/subject/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 查询课程类别 */
export async function addSubject(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/subject/add', {
    data: params,
    ...(options || {}),
  });
}

/** 查询所有课程类别 */
export async function lessonCategoryAllList(
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/lessonCategory/list', {
    ...(options || {}),
  });
}

/** 查询所有科目 */
export async function subjectAllList(
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/subject/list', {
    ...(options || {}),
  });
}

/** 查询科目列表 */
export async function lessonList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/lesson/pageList', {
    data: params,
    ...(options || {}),
  });
}


/** 添加课程  */
export async function addLesson(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/lesson/add', {
    data: params,
    ...(options || {}),
  });
}

/** 添加课程  */
export async function removeLesson(
  params: { id: number },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/lesson/remove/' + params.id, {
    data: params,
    ...(options || {}),
  });
}

/** 分页查询班级列表 */
export async function classList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    lessonMode?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/schoolClass/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 查询班级详情 */
export async function getClassDetail(
  params: {
    id
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/schoolClass/detail/' + params.id, {
    data: params,
    ...(options || {}),
  });
}

/** 查询所有班级列表 */
export async function classAllList(
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/schoolClass/list', {
    ...(options || {}),
  });
}

/** 查询班级列表 */
export async function classAndOneToOneList(
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/schoolClass/classAndOneToOneList', {
    ...(options || {}),
  });
}

/** 获取课程收费标准 */
export async function getFeeStandardByLessonId(
  params: {
    lessonId: number;
  },
  options?: { [key: string]: any },
) {
  return getRequest<API.CommonResponse<any>>(Constants.baseUrl + '/lessonFeeStandard/detail/' + params.lessonId, {
    data: params,
    ...(options || {}),
  });
}

/** 查询班级列表 */
export async function oneToOneList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    lessonMode?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/schoolClass/oneToOne/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 添加员工  */
export async function addClass(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/schoolClass/add', {
    data: params,
    ...(options || {}),
  });
}

/** 查询所有课程 */
export async function lessonAllList(
  params: {
    lessonMode?: number;
    status?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/lesson/list', {
    data: params,
    ...(options || {}),
  });
}

/** 查询所有课程 */
export async function lessonAllListByLessonMode(
  params: {
    lessonMode?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/lesson/list', {
    ...(options || {}),
  });
}

/** 查询所有教师 */
export async function teacherAllList(
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/employee/teacherList', {
    ...(options || {}),
  });
}


/** 查询教材库存记录 */
export async function textbookStorageList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/textbookStorage/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 查询教材库存记录 */
export async function textbookList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    type?: string;
  },
  options?: { [key: string]: any },
) {
  params.type = '教材';
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/textbookMisc/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 查询教材库存记录 */
export async function miscList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    type?: string;
  },
  options?: { [key: string]: any },
) {
  params.type = '杂费';
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/textbookMisc/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 添加教材 */
export async function addTextbook(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/textbookMisc/add', {
    data: params,
    ...(options || {}),
  });
}

/** 更新库存 */
export async function updateStorage(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/textbookStorage/updateStock', {
    data: params,
    ...(options || {}),
  });
}

/** 展示当前班级下学生列表 */
export async function classStudentList(
  classId?: number,
  options?: { [key: string]: any },
) {
  return getRequest<API.CommonResponse<any>>(Constants.baseUrl + '/schoolClass/studentList/' + classId, {
    ...(options || {}),
  });
}

/** 查询当前课程可分配的学员列表 */
export async function distributeList(
  classId: number | undefined,
  options?: { [p: string]: any },
) {
  return getRequest<API.CommonResponse<any>>(Constants.baseUrl + '/schoolClass/distributeList/' + classId, {
    ...(options || {}),
  });
}

/** 分配学员到班级 */
export async function distributeStudent(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/schoolClass/distributeStudent', {
    data: params,
    ...(options || {}),
  });
}

/** 办理中心 POST /order/pageList */
export async function orderList(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/order/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 查询当前课程可分配的学员列表 */
export async function studentAllList(
  options?: { [p: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/student/list', {
    ...(options || {}),
  });
}

/** 添加员工  */
export async function addOrder(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/order/create', {
    data: params,
    ...(options || {}),
  });
}

/** 展示学员报读列表 */
export async function studentLessonPageList(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/studentLesson/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 查询排课记录列表 */
export async function classScheduleRecordList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classScheduleRecord/pageList', {
    data: params,
    ...(options || {}),
  });
}


/** 查询课程表中排课记录列表 */
export async function classScheduleViewList(
  params: {},
  options?: { [key: string]: any }
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classScheduleRecord/viewList', {
    data: params,
    ...(options || {}),
  });
}

/** 查询排课记录详情 */
export async function classScheduleRecordDetail(
  params: {
    id
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classScheduleRecord/detail/' + params.id, {
    data: params,
    ...(options || {}),
  });
}

/** 查询补记上课记录列表 */
export async function queryNoRecordList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    /**查询未记录的列表**/
    status?: number;
  },
  options?: { [key: string]: any },
) {
  params.status = 0;
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classScheduleRecord/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 添加排课  */
export async function addClassSchedule(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classSchedule/add', {
    data: params,
    ...(options || {}),
  });
}

/** 排课列表  */
export async function classSchedulePageList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classSchedule/pageList', {
    data: params,
    ...(options || {}),
  });
}

/** 批量删除排课规则  */
export async function batchRemoveClassSchedule(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classSchedule/batchRemove', {
    data: params,
    ...(options || {}),
  });
}

/** 删除排课规则  */
export async function removeClassSchedule(
  params: {
    id: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classSchedule/remove/' + params.id, {
    data: params,
    ...(options || {}),
  });
}


/** 排课规则详情  */
export async function classScheduleDetail(
  params: {
    id
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classSchedule/detail/' + params.id, {
    data: params,
    ...(options || {}),
  });
}


/** 更新排课规则  */
export async function updateClassSchedule(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classSchedule/update', {
    data: params,
    ...(options || {}),
  });
}

/** 编辑排课记录  */
export async function editClassScheduleRecord(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classScheduleRecord/edit', {
    data: params,
    ...(options || {}),
  });
}


/** 记上课  */
export async function doRecord(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classScheduleRecord/doRecord', {
    data: params,
    ...(options || {}),
  });
}

/** 撤销记上课  */
export async function undoRecord(
  params: {
    id: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classScheduleRecord/undoRecord/' + params.id, {
    data: params,
    ...(options || {}),
  });
}

/** 删除排课记录  */
export async function removeRecord(
  params: {
    id: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classScheduleRecord/removeRecord/' + params.id, {
    data: params,
    ...(options || {}),
  });
}


/** 查询上课记录 */
export async function classRecordList(
  params: {
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/classScheduleRecord/classRecordPageList', {
    data: params,
    ...(options || {}),
  });
}

/** 获取验证码 五分钟有效  */
export async function getVerificationCode(
  params: {},
  options?: { [key: string]: any },
) {
  return postRequest<API.CommonResponse<any>>(Constants.baseUrl + '/student/getVerificationCode', {
    data: params,
    ...(options || {}),
  });
}
