import {ProColumns, ProTable} from "@ant-design/pro-components";
import {classList, lessonCategoryAllList} from "@/services/tmh/api";
import * as React from "react";
import {Button, Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";


interface Props {
  handleIsVisible: Function;
  setTab: Function;
  setRow: Function;
}

const ClassTable: React.FC<Props> = (props) => {

  const [lessonCategory, setLessonCategory] = useState([]);

  const getLessonSelect = async () => {
    try {
      const res = await lessonCategoryAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setLessonCategory(temp);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getLessonSelect().then();
  }, []);

  const columns: ProColumns[] = [
    {
      title: '班级名称',
      dataIndex: 'name',
      valueType: 'text',
    },
    {
      title: '满班人数',
      dataIndex: 'showCount',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师',
      dataIndex: 'teacherName',
      valueType: 'text',
    },
    {
      title: '课程名称',
      dataIndex: 'lessonName',
      valueType: 'text',
    },
    {
      dataIndex: 'lessonId',
      search: false,
      hideInTable: true,
    },
    {
      title: '课程类别',
      dataIndex: 'categoryName',
      valueType: 'text',
      search: false
    },
    {
      title: '课程类别',
      dataIndex: 'categoryId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请输入课程类别'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {lessonCategory}
          </Select>)
      },
    },
    {
      title: '收费标准',
      dataIndex: 'chargingStandard',
      search: false
    },
    {
      title: '开班日期',
      dataIndex: 'openDate',
      search: false
    },
    {
      title: '招生状态',
      dataIndex: 'status',
      valueEnum: {
        1: {
          text: '开放',
        },
        2: {
          text: '满班后停止'
        },
        3: {
          text: '停止'
        }
      },
      search: false
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      render: (_, row) => [
        <>
          <a rel="noopener noreferrer" onClick={() => {
            props.setRow(row);
            props.handleIsVisible(true);
            props.setTab(2);
          }}>
            分配学员
          </a>
        </>
      ],
    },
  ]

  const history = useHistory();

  return (
    <ProTable columns={columns}
              request={classList}
              key='class'
              search={{
                defaultCollapsed: false
              }}
              toolBarRender={() => [
                <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
                  history.push("/eduCenter/class/add");
                }}>
                  新建
                </Button>
              ]}/>
  );

}

export default ClassTable;
