import React, {FC} from 'react'
import {HashRouter as Router, Route} from 'react-router-dom'
import Container from '@/pages/container'
import Login from '@/pages/login'
import {history} from "@@/core/history";
import {queryCurrentUser} from "@/services/tmh/login";

export const getUserInfo = async (token:string) => {
  try {
    const res = await queryCurrentUser(token);
    const currentUser: API.CurrentUser = res.data;
    // 设置权限
    setPermission(currentUser);
    return currentUser;
  } catch (error) {
    history.replace('/login');
  }
  return undefined;
};

const setPermission = (currentUser: API.CurrentUser) => {
  const permission = [
    {
      code: 'eduCenter:schedule:list',
      name: '排课页'
    },
    {
      code: 'eduCenter:schedule:add',
      name: '新建排课'
    },
    {
      code: 'order:list:view',
      name: '办理中心页'
    },
    {
      code: 'order:list:add',
      name: '报课续费'
    },
    {
      code: 'eduCenter:student:list',
      name: '学员列表'
    },
    {
      code: 'eduCenter:class:list',
      name: '班级列表'
    },
    {
      code: 'eduCenter:class:add',
      name: '新建班级'
    },
    {
      code: 'eduCenter:class:assignStudent',
      name: '分配学员'
    },
    {
      code: 'eduCenter:oneToOne:list',
      name: '一对一列表'
    },
    {
      code: 'eduCenter:schedule:list',
      name: '排课列表'
    },
    {
      code: 'eduCenter:schedule:add',
      name: '新建排课'
    },
    {
      code: 'eduCenter:teachingLog:list',
      name: '记上课列表'
    },
    {
      code: 'eduCenter:teachingLog:add',
      name: '记上课'
    },
    {
      code: 'eduCenter:lesson:list',
      name: '课程列表'
    },
    {
      code: 'eduCenter:lesson:add',
      name: '新建课程'
    },
    {
      code: 'inner:employee:list',
      name: '员工列表'
    },
    {
      code: 'inner:employee:add',
      name: '新建员工'
    },
    {
      code: 'inner:textBook:list',
      name: '库存列表'
    },
  ];
  currentUser.permission = permission;
}

const App: FC = () => (
  <Router>
    <Route exact path="/login" component={Login}/>
    <Route
      path="/"
      key="container"
      render={(props: unknown) => <Container {...props} />}
    />
  </Router>
)

export default App
