import {
  HomeOutlined,
  AppstoreOutlined,
  FileTextOutlined
} from '@ant-design/icons'

const menus = [
  // {
  //   path: '/',
  //   name: '首页',
  //   key: 'home',
  //   icon: HomeOutlined,
  //   routes: []
  // },
  {
    path: '/',
    name: '办理中心',
    key: 'order:list:view',
    icon: FileTextOutlined,
    routes: []
  },
  {
    path: '/eduCenter',
    name: '教育中心',
    icon: AppstoreOutlined,
    type: 'subMenu',
    key: 'eduCenter',
    routes: [
      {
        path: '/eduCenter/student',
        name: '学员',
        key: 'eduCenter:student:list'
      },
      {
        path: '/eduCenter/class',
        name: '班级',
        key: 'eduCenter:class:list'
      },
      {
        path: '/eduCenter/oneToOne',
        name: '一对一',
        key: 'eduCenter:oneToOne:list'
      },
      {
        path: '/eduCenter/schedule',
        name: '排课',
        key: 'eduCenter:schedule:list'
      },
      {
        path: '/eduCenter/teachingLog',
        name: '记上课',
        key: 'eduCenter:teachingLog:list'
      },
      {
        path: '/eduCenter/lesson',
        name: '课程',
        key: 'eduCenter:lesson:list'
      },
    ]
  },
  {
    path: '/inner',
    name: '内部管理',
    icon: HomeOutlined,
    type: 'subMenu',
    key: 'inner',
    routes: [
      {
        path: '/inner/employee',
        name: '员工',
        key: 'inner:employee:list'
      },
      {
        path: '/inner/textBook',
        name: '教材',
        key: 'inner:textBook:list'
      },
    ]
  }

]

export default menus
