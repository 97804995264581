import {ProTable, ProColumns, PageContainer} from "@ant-design/pro-components";
import {orderList, studentAllList} from "@/services/tmh/api";
import * as React from "react";
import {Button, Select} from "antd";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

const OrderTable: React.FC = () => {

  const history = useHistory();

  const [studentList, setStudentList] = useState([]);

  const getStudentListSelect = async () => {
    try {
      const res = await studentAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option
            key={item.id}
            data-sex={item.sex}
            data-mobile={item.contactPhone}
            data-relation={item.contactRelation}
            data-courseNum={item.courseNum}
          >
            {item.name}
          </option>);
        });
        setStudentList(temp);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getStudentListSelect().then();
  }, []);


  const columns: ProColumns[] = [
    {
      title: '订单号',
      dataIndex: 'id',
      valueType: 'text',
      search: false
    },
    {
      title: '学员姓名',
      dataIndex: 'studentId',
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择学生姓名'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {studentList}
          </Select>)
      },
      hideInTable:true,
    },
    {
      title: '学员姓名',
      dataIndex: 'studentName',
      valueType: 'text',
      search: false
    },
    {
      title: '联系方式',
      dataIndex: 'contactPhone',
      valueType: 'text',
      search: false,
    },
    {
      title: '订单类型',
      dataIndex: 'tradeType',
      valueType: 'text',
      valueEnum: {
        1: {
          text: '报名',
        },
        2: {
          text: '续费'
        },
        3: {
          text: '转课'
        },
        4: {
          text: '退费'
        },
        5: {
          text: '教材杂费'
        },
      }
    },
    {
      title: '交易内容',
      dataIndex: 'tradeDesc',
      valueType: 'text',
      copyable: true,
      search: false,
      width: 240,
    },
    {
      title: '应收/应退',
      dataIndex: 'originPrice',
      valueType: 'money',
      valueEnum: {
        true: {
          text: '是',
        },
        false: {
          text: '否'
        }
      },
      search: false
    },
    {
      title: '实收/实退',
      dataIndex: 'realPrice',
      valueType: 'money',
      search: false
    },
    {
      title: '经办人',
      dataIndex: 'processEmployeeName',
      valueType: 'text',
      search: false
    },
    {
      title: '经办时间',
      dataIndex: 'processDate',
      valueType: 'text',
      search: false
    },
    {
      title: '备注',
      dataIndex: 'remark',
      valueType: 'text',
      search: false,
      ellipsis: true,
    },

  ]

  const tabList = [
    {
      key: 'tab1',
      tab: '报名续费',
    },
  ];

  return (
    <PageContainer
      tabList={tabList}
    >
      <ProTable columns={columns}
                request={orderList}
                key='order'
                scroll={{x: 1300}}
                toolBarRender={() => [
                  <Button key="button"
                          type="primary"
                          onClick={() => {
                            history.push('/order/add');
                          }}>
                    报名/续费
                  </Button>
                ]}/>
    </PageContainer>
  );

}

export default OrderTable;
