import * as React from 'react';
import {PageContainer} from "@ant-design/pro-components";
import {useState} from "react";
import StudentTable from "@/components/eduCenter/student";
import StudentLessonTable from "@/components/eduCenter/student/lesson";

const StudentTab: React.FC = () => {

  const [tabKey, setTabKey] = useState<string>('student');

  const tabList = [
    {
      key: 'student',
      tab: '花名册',
    },
    {
      key: 'studentLesson',
      tab: '报读列表',
    },
  ];

  const renderChildrenByTabKey = () => {
    if (tabKey === 'student') {
      return <StudentTable/>;
    } else if (tabKey == 'studentLesson') {
      return <StudentLessonTable/>;
    }
    return null;
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  return (
    <PageContainer
      tabList={tabList}
      tabActiveKey={tabKey}
      onTabChange={handleTabChange}
    >
      {renderChildrenByTabKey()}
    </PageContainer>
  );

}
export default StudentTab;

