import {
  Button,
  Card,
  Input,
  Form,
  InputNumber,
  Radio,
  Select,
  Table,
  Switch, message
} from 'antd';
import React, {useEffect, useState} from 'react';
import {PageContainer} from '@ant-design/pro-components';
import styles from './style.less';
import {addLesson, lessonCategoryAllList, subjectAllList} from "@/services/tmh/api";
import {useHistory} from "react-router-dom";
import {closeTabAction} from "@/assets/js/publicFunc";

const FormItem = Form.Item;
const {Option} = Select;

const LessonForm: React.FC = () => {

    const [form] = Form.useForm();

    const [lessonCategory, setLessonCategory] = useState([]);

    const [loading, setLoading] = useState(false);

    const history: CommonObjectType = useHistory();

    const cancelSubmit = () => {
      closeTabAction(history, '/eduCenter/lesson');
    };

    const [subject, setSubject] = useState([]);

    const getLessonSelect = async () => {
      try {
        const res = await lessonCategoryAllList();
        if (res.code === '0') {
          const temp: any = [];
          res.data.map(function (item: any) {
            temp.push(<option key={item.id}>{item.name}</option>);
          });
          setLessonCategory(temp);
        }
      } catch (error) {
      }
    }

    const getSubjectSelect = async () => {
      try {
        const res = await subjectAllList();
        if (res.code === '0') {
          const temp: any = [];
          res.data.map(function (item: any) {
            temp.push(<option key={item.id}>{item.name}</option>);
          });
          setSubject(temp);
        }
      } catch (error) {
      }
    }

    useEffect(() => {
      getLessonSelect().then();
      getSubjectSelect().then();
    }, []);

    const columns1 = [
      {
        title: '开课校区',
        dataIndex: 'schoolName',
      },
      {
        title: '数量（课时）',
        render: () => {
          return (
            <FormItem
              noStyle
              name="count"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: '课程名称不能为空',
                },
              ]}
            >
              <InputNumber min={1} defaultValue={1}/>
            </FormItem>
          )
        }
      },
      {
        title: ' 总价（元）',
        render: () => {
          return (
            <FormItem
              noStyle
              name="price"
              rules={[
                {
                  required: true,
                  message: '总价不能为空'
                },
              ]}
            >
              <InputNumber min={1}/>
            </FormItem>
          )
        }
      },
    ]

    const columns2 = [
      {
        title: '指定校区',
        dataIndex: 'schoolName',
      },
      {
        title: '数量',
        render: () => {
          return (
            <FormItem
              noStyle
              name="count"
              initialValue={1}
            >
              {1}
            </FormItem>
          )
        }
      },
      {
        title: '时间段',
        render: () => {
          return (
            <FormItem
              noStyle
              name="timePeriod"
              initialValue={3}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select defaultValue={3}>
                <Option value={1} selected>天</Option>
                <Option value={2} selected>周</Option>
                <Option value={3} selected>月</Option>
                <Option value={4} selected>季</Option>
                <Option value={5} selected>半年</Option>
                <Option value={6} selected>年</Option>
              </Select>
            </FormItem>
          )
        }
      },
      {
        title: ' 总价（元）',
        render: () => {
          return (
            <>
              <FormItem
                noStyle
                name="price"
                rules={[
                  {
                    required: true,
                    message: '总价不能为空'
                  },
                ]}
              >
                <InputNumber min={1}/>
              </FormItem>
            </>

          )
        }
      },
    ]

    const columns3 = [
      {
        title: '指定校区',
        dataIndex: 'schoolName',
      },
      {
        title: '数量（期）',
        render: () => {
          return (
            <FormItem
              noStyle
              name="count"
              initialValue={1}
            >
              <InputNumber min={1} defaultValue={1}/>
            </FormItem>
          )
        }
      },
      {
        title: ' 总价（元）',
        render: () => {
          return (
            <FormItem
              noStyle
              name="price"
              rules={[
                {
                  required: true,
                  message: '总价不能为空',
                },
              ]}
            >
              <InputNumber min={1}/>
            </FormItem>
          )
        }
      },
    ]

    const data = [{
      schoolName: '三音琴行主校区',
    }];
    const onFinish = async (values: { [key: string]: any }) => {
      setLoading(true);
      values.feeMode = getFeeMode;
      setTimeout(async () => {
        try {
          // 添加员工
          const data = await addLesson(values);
          if (data.code === '0') {
            message.success('添加成功！');
            closeTabAction(history, '/eduCenter/lesson');
            return;
          }
        } catch (error) {
        }
      }, 500);
      setLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
      // eslint-disable-next-line no-console
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: {span: 4},
      wrapperCol: {span: 10},
    };

    const tailLayout = {
      wrapperCol: {offset: 4, span: 16},
    };

    const [getFeeMode, setFeeMode] = useState(1);

    const [switch1, setSwitch1] = useState(true);
    const [switch2, setSwitch2] = useState(false);
    const [switch3, setSwitch3] = useState(false);

    const onSwitch1 = (checked: boolean) => {
      if (checked) {
        setSwitch2(false);
        setSwitch3(false);
      }
      setFeeMode(1);
      setSwitch1(checked);
      switchTable(1);
    }

    const onSwitch2 = (checked: any) => {
      if (checked) {
        setSwitch1(false);
        setSwitch3(false);
      }
      setFeeMode(2);
      setSwitch2(checked);
      switchTable(2);
    }

    const onSwitch3 = (checked: any) => {
      if (checked) {
        setSwitch1(false);
        setSwitch2(false);
      }
      setFeeMode(3);
      setSwitch3(checked);
      switchTable(3);
    }

    const switchTable = (feeMode: number) => {
      if (switch1 && feeMode === 1) {
        return <>
          <Form.Item
            label='定价标准'
          >
            <Table
              bordered
              pagination={false}
              dataSource={data}
              columns={columns1}
            />
          </Form.Item>
        </>
      } else if (switch2 && feeMode === 2) {
        return <>
          <Form.Item
            label='定价标准'
          >
            <Table
              bordered
              pagination={false}
              dataSource={data}
              columns={columns2}
            />
          </Form.Item>
        </>
      } else if (switch3 && feeMode === 3) {
        return <>
          <Form.Item
            label='定价标准'
          >
            <Table
              bordered
              pagination={false}
              dataSource={data}
              columns={columns3}
            />
          </Form.Item>
        </>
      }
      return null;
    }

    return (
      <Form
        style={{marginTop: 8}}
        {...layout}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <PageContainer>
          <Card className={styles.card} bordered={true} title={"课程基础信息"}>
            <FormItem
              label={'课程名称'}
              name="name"
              rules={[
                {
                  required: true,
                  message: '课程名称不能为空',
                },
              ]}
            >
              <Input placeholder={'请输入课程名称'}/>
            </FormItem>
            <FormItem
              label={'课程类别'}
              name="categoryId"
              rules={[
                {
                  required: true,
                  message: '课程类别不能为空',
                },
              ]}
            >
              <Select
                placeholder={'请选择课程类别'}
                showSearch
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {lessonCategory}
              </Select>
            </FormItem>
            <FormItem
              label={'科目'}
              name="subjectId"
              rules={[
                {
                  required: true,
                  message: '科目不能为空',
                },
              ]}
            >
              <Select
                placeholder={'请选择科目'}
                showSearch
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {subject}
              </Select>
            </FormItem>
            <Form.Item
              label={'授课模式'}
              name="lessonMode"
              initialValue={1}
            >
              <Radio.Group defaultValue={1}>
                <Radio value={1}>
                  一对一
                </Radio>
                <Radio value={2}>
                  班课
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Card>
          <Card className={styles.card} bordered={true} title={"收费模式"}>
            <Form.Item
              label={'按课时收费'}
            >
              <Switch defaultChecked onChange={onSwitch1} checked={switch1}/>
            </Form.Item>
            {switchTable(1)}
            <Form.Item
              label={'按时间收费'}
            >
              <Switch onChange={onSwitch2} checked={switch2}/>
            </Form.Item>
            {switchTable(2)}
            <Form.Item
              label={'按期收费'}
            >
              <Switch onChange={onSwitch3} checked={switch3}/>
            </Form.Item>
            {switchTable(3)}
            <FormItem label={'备注'} name={'remark'}>
              <Input.TextArea/>
            </FormItem>
            <FormItem {...tailLayout} style={{marginTop: 32}}>
              <Button type='default' onClick={cancelSubmit}>
                取消
              </Button>
              <Button type="primary" htmlType="submit" style={{marginLeft: 24}} loading={loading}>
                提交
              </Button>
            </FormItem>
          </Card>
        </PageContainer>
      </Form>
    );
  }
;
export default LessonForm;
