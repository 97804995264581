exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".header--3fj2h {\n  width: 100%;\n  background: #141414;\n  height: 64px;\n  padding: 0;\n  box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.12), 0 1.6px 3.6px rgba(0, 0, 0, 0.12);\n  z-index: 2;\n}\n.content--A0dCg {\n  height: 64px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 0 30px;\n  transition: all 0.3s;\n}\n.content--A0dCg:hover {\n  background: rgba(0, 0, 0, 0.025);\n}\n.toggleMenu--3jyRk {\n  display: inline-block;\n  height: 64px;\n  line-height: 64px;\n  padding: 0 24px;\n  font-size: 20px;\n  cursor: pointer;\n  transition: all 0.3s, padding 0s;\n}\n.avatar--28tJ6 {\n  margin-right: 8px;\n  color: #6e41ff;\n  vertical-align: top;\n  background: rgba(255, 255, 255, 0.85);\n}\n/* stylelint-disable no-duplicate-selectors */\n/* stylelint-disable */\n/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */\n", ""]);

// Exports
exports.locals = {
	"header": "header--3fj2h",
	"content": "content--A0dCg",
	"toggleMenu": "toggleMenu--3jyRk",
	"avatar": "avatar--28tJ6"
};