import Home from '@/pages/home'
import ErrorPage from '@/pages/public/errorPage'

import UserList from '@/pages/user/list'
import UserEdit from '@/pages/user/edit'

import RoleList from '@/pages/role/list'

import AuthTest from '@/pages/test'
import ScheduleTab from "@/pages/eduCenter/schedule";
import ScheduleForm from "@/pages/eduCenter/schedule/add";
import OrderTable from "@/pages/order";
import StudentTab from "@/pages/eduCenter/student";
import ClassTab from "@/pages/eduCenter/class";
import ClassForm from "@/pages/eduCenter/class/add";
import OneToOneTab from "@/pages/eduCenter/oneToOne";
import TeachingLogTab from "@/pages/eduCenter/teachingLog";
import RecordClass from "@/components/eduCenter/teachingLog/recordClass";
import LessonTab from "@/pages/eduCenter/lesson";
import LessonForm from "@/pages/eduCenter/lesson/add";
import EmployeeTab from "@/pages/inner/employee";
import EmployeeForm from "@/pages/inner/employee/add";
import TextbookMiscTab from "@/pages/inner/textBook";
import AssignStudentTable from "@/components/eduCenter/class/assignStudent";
import EnrollForm from "@/pages/order/add";

/**
 * path 跳转的路径
 * component 对应路径显示的组件
 * exact 匹配规则，true的时候则精确匹配。
 */
const menus = [
  // {
  //   path: '/',
  //   name: '首页',
  //   exact: true,
  //   key: 'home',
  //   component: Home
  // },
  {
    path: '/',
    name: '办理中心',
    exact: true,
    key: 'order:list:view',
    component: OrderTable
  },
  {
    path: '/order/add',
    name: '报课续费',
    exact: true,
    key: 'order:list:add',
    component: EnrollForm
  },
  {
    path: '/eduCenter',
    name: '教育中心',
    key: 'eduCenter',
    routes: [
      {
        path: '/eduCenter/student',
        name: '学员',
        exact: true,
        component: StudentTab,
        key: 'eduCenter:student:list'
      },
      {
        path: '/eduCenter/class',
        name: '班级',
        exact: true,
        component: ClassTab,
        key: 'eduCenter:class:list'
      },
      {
        path: '/eduCenter/class/add',
        name: '新建班级',
        exact: true,
        component: ClassForm,
        key: 'eduCenter:class:add'
      },
      {
        path: '/eduCenter/class/assignStudent',
        name: '分配学员',
        exact: true,
        component: AssignStudentTable,
        key: 'eduCenter:class:assignStudent'
      },
      {
        path: '/eduCenter/oneToOne',
        name: '一对一',
        exact: true,
        component: OneToOneTab,
        key: 'eduCenter:oneToOne:list'
      },
      {
        path: '/eduCenter/schedule',
        name: '排课',
        exact: true,
        component: ScheduleTab,
        key: 'eduCenter:schedule:list'
      },
      {
        path: '/eduCenter/schedule/add',
        name: '新建排课',
        exact: true,
        key: 'eduCenter:schedule:add',
        component: ScheduleForm
      },
      {
        path: '/eduCenter/teachingLog',
        name: '记上课列表',
        exact: true,
        component: TeachingLogTab,
        key: 'eduCenter:teachingLog:list'
      },
      {
        path: '/eduCenter/teachingLog/add',
        name: '记上课',
        exact: true,
        component: RecordClass,
        key: 'eduCenter:teachingLog:add'
      },
      {
        path: '/eduCenter/lesson',
        name: '课程',
        exact: true,
        component: LessonTab,
        key: 'eduCenter:lesson:list'
      },
      {
        path: '/eduCenter/lesson/add',
        name: '新建课程',
        exact: true,
        component: LessonForm,
        key: 'eduCenter:lesson:add'
      },
    ]
  },
  {
    path: '/inner',
    name: '内部管理',
    key: 'inner',
    routes: [
      {
        path: '/inner/employee',
        name: '员工列表',
        exact: true,
        component: EmployeeTab,
        key: 'inner:employee:list'
      },
      {
        path: '/inner/employee/add',
        name: '新建员工',
        exact: true,
        component: EmployeeForm,
        key: 'inner:employee:add'
      },
      {
        path: '/inner/textBook',
        name: '库存列表',
        exact: true,
        component: TextbookMiscTab,
        key: 'inner:textBook:list'
      },
    ]
  },

  {
    path: '/403',
    name: '暂无权限',
    exact: true,
    key: '/403',
    component: ErrorPage
  }

]

export default menus
