import {ProTable, ActionType, ProColumns, ModalForm} from "@ant-design/pro-components";
import {
  classAndOneToOneList,
  classScheduleRecordList, queryNoRecordList,
  teacherAllList, undoRecord,
} from "@/services/tmh/api";
import * as React from "react";
import {Button, Col, DatePicker, message, Popconfirm, Radio, Row, Select, Tag} from "antd";
import {useEffect, useRef, useState} from "react";
import moment from "moment";
import FormItem from "antd/es/form/FormItem";
import {useHistory} from "react-router-dom";
import {closeTab} from "@/assets/js/publicFunc";



const {RangePicker} = DatePicker;

interface Props {
  tabKey: Function;
  setCurrRow: Function;
}

const TodayRecordTable: React.FC<Props> = (props) => {

  const [teacher, setTeacher] = useState([]);

  const [tabValue, setTabValue] = useState<string>('a');

  const [formVisible, setFormVisible] = useState<boolean>(false);

  const autoReLoad = useRef<ActionType>();

  const history = useHistory();

  const [classId, setClassId] = useState<any>();

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
  };

  const getTeacherSelect = async () => {
    try {
      const res = await teacherAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(
            <option key={item.id}>
              {`${item.name}`}
            </option>);
        });
        setTeacher(temp);
      }
    } catch (error) {
    }
  }

  const [schoolClass, setSchoolClass] = useState([]);

  const getClassAllList = async () => {
    try {
      const res = await classAndOneToOneList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setSchoolClass(temp);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getTeacherSelect().then();
    getClassAllList().then();
  }, []);

  const columns: ProColumns[] = [
    {
      title: '上课日期',
      dataIndex: 'todayStart',
      valueType: 'date',
      initialValue: moment(),
      renderFormItem: () => {
        return (
          <DatePicker defaultValue={moment()}/>
        )
      },
      hideInTable: true,
    },
    {
      title: '上课时间',
      dataIndex: 'timePeriod',
      valueType: 'text',
      search: false,
    },
    {
      title: '班级/1对1',
      dataIndex: 'className',
      valueType: 'text',
      search: false,
    },
    {
      title: '班级/1对1',
      dataIndex: 'classId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择班级'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {schoolClass}
          </Select>)
      },
    },
    {
      title: '所属课程',
      dataIndex: 'lessonName',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师',
      dataIndex: 'teacherName',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师',
      dataIndex: 'teacherId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择教师'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {teacher}
          </Select>)
      },
    },
    {
      title: '实到/应到',
      dataIndex: 'actualArriveStr',
      valueType: 'text',
      search: false,
    },

    {
      title: '记上课状态',
      dataIndex: 'status',
      valueType: 'select',
      valueEnum: {
        0: "未记录",
        1: "已记录",
      },
      hideInTable: true,
    },
    {
      title: '记上课状态',
      dataIndex: 'status',
      valueType: 'select',
      render: (_, record) =>
        <Tag
          color={record.status === 0 ? 'red' : 'green'}>{record.status === 0 ? '未记录' : '已记录'}
        </Tag>,
      search: false,
    },
    {
      title: '科目',
      dataIndex: 'subjectName',
      valueType: 'text',
      search: false,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      valueType: 'text',
      search: false,
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      render: (_, row) => [
        row.status === 0 && <a
          key="a"
          onClick={() => {
            closeTab("/eduCenter/teachingLog/add");
            history.push("/eduCenter/teachingLog/add?id=" + row.id + "&editOnly=1");
          }}
        >编辑</a>,
        row.status === 0 && <a
          key="a"
          onClick={() => {
            closeTab("/eduCenter/teachingLog/add");
            history.push("/eduCenter/teachingLog/add?id=" + row.id);
          }}
        >记上课</a>,
        row.status === 1 &&
        <Popconfirm
          title="是否确定撤销当前记录?"
          onConfirm={() => {
            undoRecord({'id': row.id}).then(
              // success
              (res) => {
                if (res.code === '0') {
                  message.success(res.msg);
                  if (autoReLoad.current) {
                    autoReLoad.current.reload().then();
                  }
                } else {
                  message.error(res.msg);
                }
              },
            );
          }
          }
          okText="是"
          cancelText="否"
        >
          <a href="#">撤销</a>
        </Popconfirm>
        ,
      ],
    },
  ]


  const columns2: ProColumns[] = [
    {
      title: '上课日期',
      key: 'dateForClass',
      dataIndex: 'dateForClass',
      initialValue: [moment().subtract(1, 'months'), moment().subtract(1, 'days')],
      renderFormItem: (_, {type, defaultRender, ...rest}, form) => {
        return (
          <RangePicker
            defaultValue={[moment().subtract(1, 'months'), moment().subtract(1, 'days')]}
            disabledDate={(current) => {
              return current > moment().subtract(1, 'days').endOf('day');
            }}
          />
        )
      },
      search: {
        transform: (value: any) => ({
          dateForClassStart: value[0].substring(0, 10) + " 00:00:00",
          dateForClassEnd: value[1].substring(0, 10) + " 23:59:59"
        }),
      },
    },
    {
      title: '上课时间',
      dataIndex: 'timePeriod',
      valueType: 'text',
      search: false,
    },
    {
      title: '班级/1对1',
      dataIndex: 'className',
      valueType: 'text',
      search: false,
    },
    {
      title: '班级/1对1',
      dataIndex: 'classId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择班级'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {schoolClass}
          </Select>)
      },
    },
    {
      title: '所属课程',
      dataIndex: 'lessonName',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师',
      dataIndex: 'teacherName',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师',
      dataIndex: 'teacherId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择教师'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {teacher}
          </Select>)
      },
    },
    {
      title: '实到/应到',
      dataIndex: 'actualArriveStr',
      valueType: 'text',
      search: false,
    },
    {
      title: '记上课状态',
      dataIndex: 'status',
      valueType: 'select',
      render: (_, record) =>
        <Tag
          color={record.status === 0 ? 'red' : 'green'}>{record.status === 0 ? '未记录' : '已记录'}
        </Tag>,
      search: false,
    },
    {
      title: '科目',
      dataIndex: 'subjectName',
      valueType: 'text',
      search: false,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      valueType: 'text',
      search: false,
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      render: (_, row) => [
        row.status === 0 && <a
          key="a"
          onClick={() => {
            history.push("/eduCenter/teachingLog/add?id=" + row.id);
          }}
        >记上课</a>
      ],
    },
  ]

  return (
    <>
      <Row justify="center" style={{marginBottom: '24px'}}>
        <Col>
          <Radio.Group defaultValue="a" buttonStyle="solid"
                       onChange={(e) => {
                         setTabValue(e.target.value);
                       }}
          >
            <Radio.Button value='a'>今日上课</Radio.Button>
            <Radio.Button value='b'>补记上课</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {tabValue === 'a' ?
        <ProTable columns={columns}
                  request={classScheduleRecordList}
                  key='today'
                  search={{
                    defaultCollapsed: false
                  }}
                  actionRef={autoReLoad}
                  toolBarRender={() => [
                    <Button key="button"
                            type="primary"
                            onClick={() => {
                              setFormVisible(true);
                            }}>
                      未排课记上课
                    </Button>
                  ]}
        />
        :
        <ProTable columns={columns2}
                  request={queryNoRecordList}
                  search={{
                    defaultCollapsed: false
                  }}
        />
      }


      <ModalForm
        {...layout}
        title={'选择班级/1对1'}
        layout={'horizontal'}
        width="400px"
        onVisibleChange={setFormVisible}
        visible={formVisible}
        onFinish={async () => {
          setFormVisible(false)
          closeTab("/eduCenter/teachingLog/add");
          history.push("/eduCenter/teachingLog/add?classId=" + classId);
        }}
      >
        <FormItem
          label={'班级/1对1'}
        >
          <Select
            placeholder={'请选择班级'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={(classId) => {
              setClassId(classId);
            }}
          >
            {schoolClass}
          </Select>
        </FormItem>
      </ModalForm>
    </>

  );

}

export default TodayRecordTable;
