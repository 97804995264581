import {ActionType, ProColumns, ProTable, ModalForm} from "@ant-design/pro-components";
import {
  addStudent,
  getStudentCount,
  getVerificationCode, removeStudent,
  setStudentSwitch,
  studentAllList,
  studentList
} from "@/services/tmh/api";
import * as React from "react";
import {Button, Col, DatePicker, Form, Input, message, Popconfirm, Radio, Row, Select, Switch} from "antd";
import {HomeTwoTone, PlusOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import FormItem from "antd/es/form/FormItem";
import moment from "moment";

const {Option} = Select;

interface Student {
  name: string;
  sex: number;
  contactRelation: string;
  contactPhone: string;
  birthday: string;
  channelId: number;
  introducer: string;
  id: number;
  studentContractId: number;
}


const StudentTable: React.FC = () => {

  const [formVisible, setFormVisible] = useState(false);

  const [studentCount, setStudentCount] = useState(0);

  const autoReLoad = useRef<ActionType>();

  const [form] = Form.useForm();

  const {resetFields} = form;

  const [bindForm] = Form.useForm();

  const [bindFormVisible, setBindFormVisible] = useState<boolean>(false);

  const [verificationCode, setVerificationCode] = useState<number>();

  const [studentListAll, setStudentList] = useState([]);

  const [operateType, setOperateType] = useState<string>();

  const [currRow, setCurrRow] = useState<Student>();


  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
  };

  const fieldLabels = {
    name: '姓名',
    sex: '性别',
    birthday: '生日',
    channelId: '来源渠道',
    contactRelation: '联系人关系',
    contactPhone: '联系电话',
    introducer: '介绍人'
  };


  const getStudentListSelect = async () => {
    try {
      const res = await studentAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option
            key={item.id}
            data-sex={item.sex}
            data-mobile={item.contactPhone}
            data-relation={item.contactRelation}
            data-courseNum={item.courseNum}
          >
            {item.name}
          </option>);
        });
        setStudentList(temp);
      }
    } catch (error) {
    }
  }

  const onConfirm = async (id) => {
    try {
      const data = await removeStudent({'id': id});
      if (data.code === '0') {
        message.success(data.msg);
        if (autoReLoad.current) {
          autoReLoad.current.reload();
        }
      } else {
        message.error(data.msg);
      }
    } catch (e) {
    }
  }

  useEffect(() => {
    getStudentListSelect().then();
  }, []);


  const columns: ProColumns[] = [
    {
      title: '学员姓名',
      dataIndex: 'studentId',
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择学生姓名'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {studentListAll}
          </Select>)
      },
      hideInTable: true,
    },
    {
      title: '学生姓名',
      dataIndex: 'name',
      valueType: 'text',
      search: false
    },
    {
      title: '性别',
      dataIndex: 'sex',
      valueType: 'text',
      key: 'sex',
      valueEnum: {
        男: {
          text: '男',
        },
        女: {
          text: '女'
        },
        保密: {
          text: '保密'
        }
      }
    },

    {
      title: '关系',
      dataIndex: 'contactRelation',
      valueType: 'select',
      key: 'contactRelation',
      search: false
    },
    {
      title: '联系电话',
      dataIndex: 'contactPhone',
      valueType: 'text',
      key: 'contactPhone',
      search: false
    },
    {
      title: '年龄',
      dataIndex: 'age',
      valueType: 'text',
      key: 'age',
      renderText: (val: (number | undefined)) => {
        if (typeof val === 'undefined') {
          return '-';
        } else if (val <= 0) {
          return '-';
        }
        return `${val}岁`
      },
      search: false
    },
    {
      title: '学员状态',
      dataIndex: 'status',
      valueType: 'select',
      key: 'status',
      valueEnum: {
        1: {text: '在读', status: 'Success'},
        3: {text: '结课', status: 'Default'},
        2: {text: '停课', status: 'Error'},
      }
    },
    {
      title: '在读课程',
      dataIndex: 'courseNum',
      valueType: 'text',
      search: false,
      key: 'courseNum',
    },
    {
      title: '生日',
      dataIndex: 'birthday',
      valueType: 'text',
      search: false,
      key: 'birthday',
    },
    {
      title: '是否关注公众号',
      dataIndex: 'isLinkMp',
      valueType: 'text',
      key: 'isLinkMp',
      search: false,
      render: (_, record) => {
        if (record.isLinkMp === 1) {
          return (<HomeTwoTone twoToneColor="#00FFFF" style={{fontSize: '20px'}}/>);
        } else {
          return (<HomeTwoTone twoToneColor="#D3D3D3" style={{fontSize: '20px'}}/>);
        }
      }
    },
    {
      title: '公众号上课提醒',
      key: 'openMpClassReminder',
      valueType: 'option',
      render: (_, record) => [
        record.openMpClassReminder && <Switch defaultChecked
                                              onChange={(checked) => {
                                                requestSetStudentSwitch(1, record.id, checked).then();
                                              }
                                              }
        />,
        !record.openMpClassReminder && <Switch onChange={(checked) => {
          requestSetStudentSwitch(1, record.id, checked).then();
        }
        }
        />
      ],
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      render: (_, row) => [
        <a key="edit"
           onClick={() => {
             resetFields();
             setCurrRow(row);
             setFormVisible(true);
             setOperateType('edit');
           }}
        >编辑</a>,
        row.isLinkMp === 0 && <a
          key="a"
          onClick={() => {
            requestVerificationCode(row).then();
            setCurrRow(row);
            setBindFormVisible(true);
          }}
        >绑定</a>,
        <Popconfirm
          title="是否确定删除当前记录?"
          onConfirm={() => {
            onConfirm(row.id).then();
          }}
          okText="是"
          cancelText="否"
        >
          <a href="#">删除</a>
        </Popconfirm>
      ],
    },
  ]

  const requestVerificationCode = async (row: any) => {
    const res = await getVerificationCode({"studentId": row.id});
    if (res.code === '0') {
      setVerificationCode(res.data);
    }
  }

  const requestSetStudentSwitch = async (type: number, studentId: number, enable: boolean) => {
    const res = await setStudentSwitch({"type": type, "studentId": studentId, "enable": enable});
    if (res.code === '0') {
      message.success('设置成功！');
    } else {
      message.error(res.msg);
    }
  }

  const getCount = async () => {
    try {
      const res = await getStudentCount();
      if (res.code === '0') {
        setStudentCount(res.data);
      }
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ProTable columns={columns}
                request={async (params) => {
                  let result = null;
                  await studentList(params).then(res => {
                    result = res;
                  });
                  let promise = Promise.resolve(result);
                  getCount().then();
                  return promise;
                }}
                  actionRef={autoReLoad}
                  rowKey={record => record.id}
                  headerTitle={`当前学员数量：${studentCount} 名`}
                  toolBarRender={() => [
                  <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
                    resetFields();
                    setOperateType('add');
                    setFormVisible(true);
                  }}>
                  新建
                  </Button>
                  ]}/>
                  <ModalForm
                  form={form}
                  title={operateType === 'add' ? '添加学员' : '编辑学员'}
                  width="680px"
                  onVisibleChange={setFormVisible}
                  visible={formVisible}
                  onFinish={async (values) => {
                    if (operateType === 'edit') {
                      values.id = currRow.id;
                      values.studentContractId = currRow.studentContractId;
                    }
                    if (values.birthday) {
                      values.birthday = values.birthday.substr(0, 10);
                    }
                    console.log(values);
                    setTimeout(async () => {
                      try {
                        // 添加学员
                        const data = await addStudent(values);
                        if (data.code === '0') {
                          message.success(operateType === 'add' ? '添加成功！' : '修改成功');
                          setFormVisible(false);
                          if (autoReLoad.current) {
                            autoReLoad.current.reload();
                          }
                          form.resetFields();
                          return;
                        }
                        // 如果失败去设置用户错误信息
                      } catch (error) {
                      }
                    }, 500);
                  }}
                  >
                  <Row gutter={16} justify="space-around" align="middle">
                  <Col span={10}>
                  <Form.Item
                  label={fieldLabels.name}
                  name="name"
                  rules={[{required: true, message: '姓名不能为空'}]}
                  initialValue={operateType === 'edit' ? currRow.name : undefined}
                  >
                  <Input placeholder="请输入学员姓名" defaultValue={operateType === 'edit' ? currRow.name : undefined}/>
                  </Form.Item>
                  </Col>
                  <Col span={10}>
                  <Form.Item
                  label={fieldLabels.sex}
                  name="sex"
                  initialValue={operateType === 'edit' ? currRow.sex : '女'}
                  >
                  <Radio.Group defaultValue={operateType === 'edit' ? currRow.sex : '女'}>
                  <Radio value={'女'}>
                  女
                  </Radio>
                  <Radio value={'男'}>
                  男
                  </Radio>
                  <Radio value={'保密'}>
                  保密
                  </Radio>
                  </Radio.Group>
                  </Form.Item>
                  </Col>
                  </Row>
                  <Row gutter={16} justify="space-around" align="middle">
                  <Col span={10}>
                  <Form.Item label="联系人">
                  <Input.Group compact>
                  <Form.Item
                  name="contactRelation"
                  noStyle
                  initialValue={operateType === 'edit' ? currRow.contactRelation : '母亲'}
                  >
                  <Select defaultValue={operateType === 'edit' ? currRow.contactRelation : '母亲'} style={{width: '30%'}}>
                  <Option value={'母亲'}>母亲</Option>
                  <Option value={'父亲'}>父亲</Option>
                  <Option value={'本人'}>本人</Option>
                  <Option value={'其他'}>其他</Option>
                  </Select>
                  </Form.Item>
                  <Form.Item
                  name={'contactPhone'}
                  noStyle
                  rules={[{required: true, message: '手机号不能为空'}]}
                  initialValue={operateType === 'edit' ? currRow.contactPhone : undefined}
                  >
                  <Input style={{width: '70%'}} placeholder="请输入手机号"
                  defaultValue={operateType === 'edit' ? currRow.contactPhone : undefined}/>
                  </Form.Item>
                  </Input.Group>
                  </Form.Item>
                  </Col>
                  <Col span={10}>
                  <Form.Item
                  label={fieldLabels.birthday}
                  name="birthday"
                  initialValue={operateType === 'edit' ?
                    (currRow.birthday === undefined || currRow.birthday === '' ? undefined : moment(currRow.birthday)) : undefined}
                  >
                  <DatePicker placeholder={'请选择学员生日'} style={{width: '100%'}}
                  defaultValue={operateType === 'edit' ?
                    (currRow.birthday === undefined || currRow.birthday === '' ? undefined : moment(currRow.birthday)) : undefined}/>
                  </Form.Item>
                  </Col>
                  </Row>

                  <Row gutter={16} justify="space-around" align="middle">
                  <Col span={10}>
                  <Form.Item
                  label={fieldLabels.channelId}
                  name="channelId"
                  initialValue={operateType === 'edit' ? currRow.channelId : 1}
                  >
                  <Select defaultValue={operateType === 'edit' ? currRow.channelId : 1}>
                  <Option value={1}>自然上门</Option>
                  <Option value={2}>教师推荐</Option>
                  <Option value={3}>老学员介绍</Option>
                  <Option value={4}>地推活动</Option>
                  </Select>
                  </Form.Item>
                  </Col>
                  <Col span={10}>
                  <Form.Item
                  label={fieldLabels.introducer}
                  name="introducer"
                  initialValue={operateType === 'edit' ? currRow.introducer : undefined}
                  >
                  <Input placeholder="请输入介绍人" defaultValue={operateType === 'edit' ? currRow.introducer : undefined}/>
                  </Form.Item>
                  </Col>
                  </Row>
                  </ModalForm>

                  <ModalForm
                  form={bindForm}
                  {...layout}
                  title={'绑定公众号'}
                  layout={'horizontal'}
                  width="400px"
                  onVisibleChange={setBindFormVisible}
                  visible={bindFormVisible}
                  onFinish={async (values) => {
                    setBindFormVisible(false);
                  }}
                  >
                  <FormItem
                  label={'学员名称'}
                  >
                  {currRow?.name}
                  </FormItem>
                  <FormItem
                  label={'验证码'}
                  >
                  <Input value={verificationCode} readOnly style={{width: '70%'}}/>
                  </FormItem>
                  </ModalForm>
                  </>
                  );

                }

                export default StudentTable;
