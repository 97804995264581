// @ts-ignore
/* eslint-disable */
import {getByToken, getRequest, request} from '@/utils/request';
import {Constants} from "@/utils/constants";

/** 获取当前的用户 GET /api/currentUser */
export async function queryCurrentUser(params: {}, options?: { [key: string]: any }) {
  return getByToken<API.CommonResponse<API.CurrentUser>>(Constants.baseUrl + '/user/getCurrentUser', {
    data:params,
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/login/outLogin', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  return request<API.loginResponse>(Constants.baseUrl + '/user/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

