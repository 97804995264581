import {ActionType, ProColumns, ProTable} from "@ant-design/pro-components";
import {lessonCategoryAllList, lessonList, removeLesson, subjectAllList} from "@/services/tmh/api";
import * as React from "react";
import {Button, message, Popconfirm, Select} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";


const LessonTable: React.FC = () => {

  const history = useHistory();

  const [lessonCategory, setLessonCategory] = useState([]);

  const [subject, setSubject] = useState([]);

  const autoReLoad = useRef<ActionType>();

  const getLessonSelect = async () => {
    try {
      const res = await lessonCategoryAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setLessonCategory(temp);
      }
    } catch (error) {
    }
  }

  const getSubjectSelect = async () => {
    try {
      const res = await subjectAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setSubject(temp);
      }
    } catch (error) {
    }
  }

  const remove = async (id) => {
    try {
      const res = await removeLesson({id});
      if (res.code === '0') {
        message.success(res.msg);
        if (autoReLoad.current) {
          autoReLoad.current.reload();
        }
      } else {
        message.error(res.msg);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getLessonSelect().then();
    getSubjectSelect().then();
  }, []);


  const columns: ProColumns[] = [
    {
      title: '课程名称',
      dataIndex: 'name',
      valueType: 'text',
    },
    {
      title: '课程类别',
      dataIndex: 'categoryName',
      valueType: 'text',
      search: false,
    },
    {
      title: '科目',
      dataIndex: 'subjectName',
      valueType: 'text',
      search: false,
    },
    {
      title: '课程类别',
      dataIndex: 'categoryId',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请输入课程类别'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {lessonCategory}
          </Select>)
      },
    },
    {
      title: '科目',
      dataIndex: 'subjectId',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请输入科目'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {subject}
          </Select>)
      },
    },
    {
      title: '授课模式',
      dataIndex: 'lessonMode',
      valueType: 'select',
      valueEnum: {
        1: {text: '一对一'},
        2: {text: '班课'},
      }
    },
    {
      title: '课程状态',
      dataIndex: 'status',
      valueType: 'select',
      valueEnum: {
        true: {text: '开售'},
        false: {text: '停售'},
      }
    },
    {
      title: '收费模式',
      dataIndex: 'feeMode',
      search: false,
      valueEnum: {
        1: {text: '按课时'},
        2: {text: '按时间段'},
        3: {text: '按期'},
      }
    },
    {
      title: '开班数',
      dataIndex: 'openingNum',
      valueType: 'text',
      search: false,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      valueType: 'text',
      search: false
    },
    {
      title: '备注',
      dataIndex: 'remark',
      valueType: 'text',
      search: false
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      render: (_, row) => [
        <Popconfirm
          title="是否确定删除当前记录?"
          onConfirm={() => {
            remove(row.id).then();
          }}
          okText="是"
          cancelText="否"
        >
          <a href="#">删除</a>
        </Popconfirm>

      ]
    }
  ]


  return (
    <ProTable columns={columns}
              request={lessonList}
              key='lesson'
              actionRef={autoReLoad}
              search={{
                defaultCollapsed: false
              }}
              toolBarRender={() => [
                <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
                  history.push('/eduCenter/lesson/add');
                }}>
                  新建
                </Button>
              ]}/>
  );

}

export default LessonTable;
