import * as React from 'react';
import {PageContainer} from "@ant-design/pro-components";
import {useState} from "react";
import ClassTable from "@/components/eduCenter/class";
import ClassForm from "@/pages/eduCenter/class/add";
import RoleTree from "@/components/role";
import AssignStudentTable from "@/components/eduCenter/class/assignStudent";

const ClassTab: React.FC = () => {

  const [tabKey, setTabKey] = useState<string>('class');

  const [formVisible, setFormVisible] = useState<boolean>(false);

  const [currRow, setCurrRow] = useState<TMH.SchoolClass>({});

  const [tab, setTab] = useState<number>(0);

  // tab
  const tabList = [
    {
      key: 'class',
      tab: '班级管理',
    },
    // {
    //   key: 'log',
    //   tab: '班级操作日志',
    // },
  ];

  const renderChildrenByTabKey = () => {
    if (tabKey === 'class') {
      return <ClassTable handleIsVisible={setFormVisible} setTab={setTab} setRow={setCurrRow}/>;
    } else if (tabKey === 'log') {
      return <RoleTree/>;
    }
    return null;
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  if (formVisible && tab === 1) {
    return (<ClassForm/>)
  } else if (formVisible && tab === 2) {
    return (<AssignStudentTable handleIsVisible={setFormVisible} row={currRow}/>)
  } else {
    return (
      <PageContainer
        tabList={tabList}
        tabActiveKey={tabKey}
        onTabChange={handleTabChange}
      >
        {renderChildrenByTabKey()}
      </PageContainer>
    );
  }

}

export default ClassTab;
