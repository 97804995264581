import {ProTable, ActionType, ProColumns} from "@ant-design/pro-components";
import {
  classAndOneToOneList, classRecordList,
  teacherAllList
} from "@/services/tmh/api";
import * as React from "react";
import {Select, Tag} from "antd";
import {useEffect, useRef, useState} from "react";

interface Props {
  tabKey: Function;
}

const ClassRecordTable: React.FC<Props> = (props) => {

  const autoReLoad = useRef<ActionType>();

  const [teacher, setTeacher] = useState([]);

  const getTeacherSelect = async () => {
    try {
      const res = await teacherAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(
            <option key={item.id}>
              {`${item.name}`}
            </option>);
        });
        setTeacher(temp);
      }
    } catch (error) {
    }
  }

  const [schoolClass, setSchoolClass] = useState([]);

  const getClassAllList = async () => {
    try {
      const res = await classAndOneToOneList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setSchoolClass(temp);
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    getTeacherSelect().then();
    getClassAllList().then();
  }, []);

  const columns: ProColumns[] = [
    {
      title: '上课日期',
      dataIndex: 'dateForClassStr',
      valueType: 'text',
      search: false,
    },
    {
      title: '上课日期',
      key: 'dateRange',
      valueType: 'dateRange',
      search: {
        transform: (value: any) => ({
          dateForClassStart: value[0] + " 00:00:00",
          dateForClassEnd: value[1] + " 23:59:59"
        }),
      },
      hideInTable: true,
    },
    {
      title: '上课时间',
      dataIndex: 'timePeriod',
      valueType: 'text',
      search: false,
    },
    {
      title: '班级/1对1',
      dataIndex: 'className',
      valueType: 'text',
      search: false,
    },
    {
      title: '班级/1对1',
      dataIndex: 'classId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择班级'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {schoolClass}
          </Select>)
      },
    },
    {
      title: '所属课程',
      dataIndex: 'lessonName',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师',
      dataIndex: 'teacherName',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师',
      dataIndex: 'teacherId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择教师'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {teacher}
          </Select>)
      },
    },
    {
      title: '实到/应到',
      dataIndex: 'actualArriveStr',
      valueType: 'text',
      search: false,
    },
    {
      title: '学员课时',
      dataIndex: 'studentReduceHour',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师课时',
      dataIndex: 'teacherAddHour',
      valueType: 'text',
      search: false,
    },
    {
      title: '记上课状态',
      dataIndex: 'status',
      valueType: 'select',
      render: (_, record) =>
        <Tag
          color={record.status === 0 ? 'red' : 'green'}>{record.status === 0 ? '未记录' : '已记录'}
        </Tag>,
      search: false,
    },
    {
      title: '备注',
      dataIndex: 'remark',
      valueType: 'text',
      search: false,
    },
    {
      title: '到课',
      dataIndex: 'normal',
      valueType: 'text',
      search: false,
    },
    {
      title: '请假',
      dataIndex: 'leave',
      valueType: 'text',
      search: false,
    },
    {
      title: '缺课',
      dataIndex: 'absent',
      valueType: 'text',
      search: false,
    },
  ]


  return (
    <>
      <ProTable columns={columns}
                request={classRecordList}
                key='today'
                actionRef={autoReLoad}
                search={{
                  defaultCollapsed: false
                }}
        // toolBarRender={() => [
        //   <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
        //     props.handleIsVisible(true)
        //   }}>
        //     未排课记课
        //   </Button>
        // ]}
      />
    </>

  );

}

export default ClassRecordTable;
