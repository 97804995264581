import {ProTable, ActionType, ProColumns} from "@ant-design/pro-components";
import {
  batchRemoveClassSchedule,
  classAndOneToOneList, classSchedulePageList,
  removeClassSchedule,
  teacherAllList
} from "@/services/tmh/api";
import * as React from "react";
import {Button, message, Modal, Popconfirm, Select, Table} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";

const ScheduleListTable: React.FC = () => {

  const [teacher, setTeacher] = useState([]);

  const history = useHistory();

  const autoReLoad = useRef<ActionType>();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [confirmVisible, setConfirmVisible] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const getTeacherSelect = async () => {
    try {
      const res = await teacherAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(
            <option key={item.id}>
              {`${item.name}`}
            </option>);
        });
        setTeacher(temp);
      }
    } catch (error) {
    }
  }

  const [schoolClass, setSchoolClass] = useState([]);

  const getClassAllList = async () => {
    try {
      const res = await classAndOneToOneList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setSchoolClass(temp);
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    getTeacherSelect().then();
    getClassAllList().then();
  }, []);


  const onConfirm = async (id) => {
    try {
      const data = await removeClassSchedule({'id': id});
      if (data.code === '0') {
        message.success(data.msg);
        if (autoReLoad.current) {
          autoReLoad.current.reload();
        }
      } else {
        message.error(data.msg);
      }
    } catch (e) {
    }
  }

  const batchRemove = async () => {
    try {
      const data = await batchRemoveClassSchedule({'classScheduleIds': selectedRowKeys});
      if (data.code === '0') {
        message.success(data.msg);
        if (autoReLoad.current) {
          autoReLoad.current.reload();
          setSelectedRowKeys([]);
        }
      } else {
        message.error(data.msg);
      }
    } catch (e) {
    }
  }

  const columns: ProColumns[] = [
    {
      title: '创建时间',
      dataIndex: 'createTime',
      valueType: 'dateRange',
      search: {
        transform: (value: any) => ({
          createTimeStart: value[0] + " 00:00:00",
          createTimeEnd: value[1] + " 23:59:59"
        }),
      },
      hideInTable: true,
    },
    {
      title: '班级/1对1',
      dataIndex: 'className',
      valueType: 'text',
      search: false,
    },
    {
      title: '班级/1对1',
      dataIndex: 'classId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择班级'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {schoolClass}
          </Select>)
      },
    },
    {
      title: '教师',
      dataIndex: 'teacherName',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师',
      dataIndex: 'teacherId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择教师'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {teacher}
          </Select>)
      },
    },
    {
      title: '排课模式',
      dataIndex: 'mode',
      valueType: 'select',
      valueEnum: {
        1: {
          text: '自由排课',
        },
        2: {
          text: '重复排课',
        },
      },
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      valueType: 'text',
      search: false,
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      render: (_, row) => [
        <a key="edit"
           onClick={() => {
             history.push('/eduCenter/schedule/add?id=' + row.id);
           }}
        >编辑</a>,
        <Popconfirm
          title="删除当前排课规则，将同时删除规则生成的状态为未记录的排课记录，是否确认?"
          onConfirm={() => {
            onConfirm(row.id).then();
          }}
          okText="是"
          cancelText="否"
        >
          <a href="#">删除</a>
        </Popconfirm>

      ]
    }
  ]

  return (
    <ProTable columns={columns}
              request={classSchedulePageList}
              key='tab2'
              rowKey={record => record.id}
              rowSelection={{
                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                defaultSelectedRowKeys: selectedRowKeys,
                selectedRowKeys: selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedRowKeys(selectedRowKeys);
                }
              }}
              search={{
                defaultCollapsed: false
              }}
              actionRef={autoReLoad}
              toolBarRender={() => [
                <Button key="button" icon={<PlusOutlined/>} type="primary" onClick={() => {
                  history.push('/eduCenter/schedule/add');
                }}>
                  新建排课
                </Button>,
                <Button key="button" icon={<DeleteOutlined/>} type="primary" onClick={() => {
                  if (selectedRowKeys.length == 0) {
                    message.error('请先勾选要删除的排课规则').then(r => {
                    });
                  } else {
                    setConfirmVisible(true);
                  }
                }}>
                  批量删除
                </Button>,
                <Modal
                  title="批量删除"
                  centered={true}
                  visible={confirmVisible}
                  width={480}
                  onOk={() => {
                    setConfirmLoading(true);
                    setTimeout(() => {
                      setConfirmVisible(false);
                      setConfirmLoading(false);
                    }, 1000);
                    batchRemove().then();
                  }}
                  confirmLoading={confirmLoading}
                  onCancel={() => {
                    setConfirmVisible(false)
                  }}
                >
                  <p>{'删除排课规则，将同时删除规则生成的状态为未记录的排课记录，是否确认您确认批量删除排课规则吗？'}</p>
                </Modal>
              ]}/>
  );

}

export default ScheduleListTable;
