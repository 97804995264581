import {ProTable, ActionType, ProColumns, ModalForm} from "@ant-design/pro-components";
import {
  lessonCategoryAllList,
  textbookStorageList,
  updateStorage
} from "@/services/tmh/api";
import * as React from "react";
import {Form, InputNumber, message, Radio, Select} from "antd";
import {useEffect, useRef, useState} from "react";
import FormItem from "antd/es/form/FormItem";


interface Props {
  handleIsVisible: Function;
}

const TextbookStorageTable: React.FC<Props> = (props) => {


  const [lessonCategory, setLessonCategory] = useState([]);

  const [updateStockVisible, setUpdateStockVisible] = useState(false);

  const [currRow, setCurrRow] = useState<TMH.TextbookStorage>({});

  const autoReLoad = useRef<ActionType>();

  const [form] = Form.useForm();

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
  };

  const getLessonSelect = async () => {
    try {
      const res = await lessonCategoryAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setLessonCategory(temp);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getLessonSelect().then();
  }, []);


  const columns: ProColumns[] = [
    {
      title: '教材名称',
      dataIndex: 'textbookName',
      valueType: 'text',
    },
    {
      title: '库存',
      dataIndex: 'stock',
      valueType: 'text',
      search: false,
    },
    {
      title: '课程类别',
      dataIndex: 'lessonCategoryName',
      valueType: 'text',
      search: false,
    },
    {
      title: '状态',
      dataIndex: 'status',
      valueType: 'select',
      valueEnum: {
        1: {text: '正常'},
        0: {text: '下架'},
      }
    },
    {
      title: '课程类别',
      dataIndex: 'lessonCategoryId',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请输入课程类别'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            <option value={-1}>通用教材/杂费</option>
            {lessonCategory}
          </Select>)
      },
    },
    {
      title: '操作',
      key: 'option',
      valueType: 'option',
      render: (_, row) => [
        <a
          key="a"
          onClick={() => {
            setUpdateStockVisible(true)
            setCurrRow(row);
          }}
        >
          修改库存
        </a>,
      ],
    },
  ]


  return (
    <>
      <ProTable columns={columns}
                request={textbookStorageList}
                key='storage'
                actionRef={autoReLoad}
      />
      <ModalForm

        form={form}
        {...layout}
        title={'修改库存'}
        layout={'horizontal'}
        width="450px"
        onVisibleChange={setUpdateStockVisible}
        visible={updateStockVisible}
        onFinish={async (values) => {
          values.id = currRow?.id;
          if (values.type === 0) {
            values.num = 0 - values.num;
          }
          values.stock = currRow.stock + values.num;
          setTimeout(async () => {
            try {
              const data = await updateStorage(values);
              if (data.code === '0') {
                message.success('修改库存成功！');
                setUpdateStockVisible(false);
                if (autoReLoad.current) {
                  autoReLoad.current.reload();
                }
                form.resetFields();
                return;
              }
              // 如果失败去设置用户错误信息
            } catch (error) {
            }
          }, 400);
        }}
      >
        <FormItem
          label={'教材名称'}
        >
          {currRow?.textbookName}
        </FormItem>

        <FormItem
          label={'操作类型'}
          name="type"
          initialValue={1}
        >
          <Radio.Group defaultValue={1}>
            <Radio value={1}>
              加库存
            </Radio>
            <Radio value={0}>
              减库存
            </Radio>
          </Radio.Group>
        </FormItem>

        <FormItem
          label={'操作数量'}
          name="num"
          rules={[{required: true, message: '数量不能为空'}]}
          initialValue={1}
        >
          <InputNumber min={1} style={{width: '80%'}}/>
        </FormItem>
      </ModalForm>
    </>

  );

}

export default TextbookStorageTable;
