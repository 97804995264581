import {ProTable, ProColumns} from "@ant-design/pro-components";
import {
  classAndOneToOneList,
  classScheduleRecordList,
  teacherAllList
} from "@/services/tmh/api";
import * as React from "react";
import {DatePicker, Select, Tag} from "antd";
import {useEffect, useState} from "react";
import moment from "moment";


const {RangePicker} = DatePicker;

const CalendarListTable: React.FC = () => {

  const [teacher, setTeacher] = useState([]);

  const getTeacherSelect = async () => {
    try {
      const res = await teacherAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(
            <option key={item.id}>
              {`${item.name}`}
            </option>);
        });
        setTeacher(temp);
      }
    } catch (error) {
    }
  }

  const [schoolClass, setSchoolClass] = useState([]);

  const getClassAllList = async () => {
    try {
      const res = await classAndOneToOneList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option key={item.id}>{item.name}</option>);
        });
        setSchoolClass(temp);
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    getTeacherSelect().then();
    getClassAllList().then();
  }, []);

  const columns: ProColumns[] = [
    {
      title: '上课日期',
      dataIndex: 'dateForClassStr',
      valueType: 'text',
      search: false,
    },
    // {
    //   title: '上课日期',
    //   key: 'dateForClass',
    //   initialValue: [moment().format('YYYY-MM-DD'), moment().add(7, 'days').format('YYYY-MM-DD')],
    //   renderFormItem: (_, {type, defaultRender, ...rest}, form) => {
    //     return (
    //       <RangePicker
    //         defaultValue={}
    //       />
    //     )
    //   },
    //   search: {
    //     transform: (value: any) => ({
    //       dateForClassStart: value[0] + " 00:00:00",
    //       dateForClassEnd: value[1] + " 23:59:59"
    //     }),
    //   },
    //   hideInTable: true,
    // },
    {
      title: '上课日期',
      key: 'dateForClass',
      initialValue: [moment(), moment().add(7, 'days')],
      renderFormItem: (_, {type, defaultRender, ...rest}, form) => {
        return (
          <RangePicker
            defaultValue={[moment(), moment().add(7, 'days')]}
          />
        )
      },
      search: {
        transform: (value: any) => ({
          dateForClassStart: value[0].substring(0, 10) + " 00:00:00",
          dateForClassEnd: value[1].substring(0, 10) + " 23:59:59"
        }),
      },
      hideInTable: true,
    },
    {
      title: '时间段',
      dataIndex: 'timePeriod',
      valueType: 'text',
      search: false,
    },
    {
      title: '班级/1对1',
      dataIndex: 'className',
      valueType: 'text',
      search: false,
    },
    {
      title: '班级/1对1',
      dataIndex: 'classId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择班级'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {schoolClass}
          </Select>)
      },
    },
    {
      title: '教师',
      dataIndex: 'teacherName',
      valueType: 'text',
      search: false,
    },
    {
      title: '教师',
      dataIndex: 'teacherId',
      valueType: 'text',
      hideInTable: true,
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择教师'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {teacher}
          </Select>)
      },
    },
    // {
    //   title: '科目',
    //   dataIndex: 'subjectId',
    //   hideInTable: true,
    //   renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
    //     return (
    //       <Select
    //         placeholder={'请输入科目'}
    //         showSearch
    //         filterOption={(input, option: any) =>
    //           option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //         }>
    //         {subject}
    //       </Select>)
    //   },
    // },
    {
      title: '记上课状态',
      dataIndex: 'status',
      valueType: 'select',
      render: (_, record) =>
        <Tag
          color={record.status === 0 ? 'red' : 'green'}>{record.status === 0 ? '未记录' : '已记录'}
        </Tag>,
      search: false,
    },

    {
      title: '实到/应到',
      dataIndex: 'actualArriveStr',
      valueType: 'text',
      search: false,
    },
  ]

  return (
    <ProTable columns={columns}
              request={classScheduleRecordList}
              key='tab3'
              search={{
                defaultCollapsed: false
              }}
             />
  );

}

export default CalendarListTable;
