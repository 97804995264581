import {ProTable, ActionType, ProColumns} from "@ant-design/pro-components";
import {studentAllList, studentLessonPageList} from "@/services/tmh/api";
import * as React from "react";
import {Select} from "antd";
import {useEffect, useRef, useState} from "react";

const StudentLessonTable: React.FC = () => {

  const [studentCount, setStudentCount] = useState(0);

  const autoReLoad = useRef<ActionType>();

  const [studentList, setStudentList] = useState([]);


  const getStudentListSelect = async () => {
    try {
      const res = await studentAllList();
      if (res.code === '0') {
        const temp: any = [];
        res.data.map(function (item: any) {
          temp.push(<option
            key={item.id}
            data-sex={item.sex}
            data-mobile={item.contactPhone}
            data-relation={item.contactRelation}
            data-courseNum={item.courseNum}
          >
            {item.name}
          </option>);
        });
        setStudentList(temp);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    getStudentListSelect().then();
  }, []);


  const columns: ProColumns[] = [
    {
      title: '学员姓名',
      dataIndex: 'studentId',
      renderFormItem: (item, {type, defaultRender, ...rest}, form) => {
        return (
          <Select
            placeholder={'请选择学生姓名'}
            showSearch
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {studentList}
          </Select>)
      },
      hideInTable:true,
    },
    {
      title: '学生姓名',
      dataIndex: 'name',
      valueType: 'text',
      search: false
    },
    {
      title: '关系',
      dataIndex: 'contactRelation',
      valueType: 'select',
      search: false
    },
    {
      title: '报读课程',
      dataIndex: 'lessonName',
      valueType: 'text',
      search: false
    },
    {
      title: '授课模式',
      dataIndex: 'lessonMode',
      valueEnum: {
        1: {text: '一对一'},
        2: {text: '班课'},
      },
      search: false
    },
    {
      title: '已用课时',
      dataIndex: 'usedHour',
      search: false,
      renderText: (val: string) => {
        if (typeof val === 'undefined') {
          return '-';
        }
        return `${val}课时`;
      }
    },
    {
      title: '剩余课时',
      dataIndex: 'remainingHour',
      search: false,
      renderText: (val: string) => {
        if (typeof val === 'undefined') {
          return '-';
        }
        return `${val}课时`;
      }
    },
  ]


  const getListData = async (values: any) => {
    const data = await studentLessonPageList(values);
    setStudentCount(0);
    if (data.success && data.total > 0) {
      setStudentCount(data.total);
    }
    return data;
  };


  return (
    <>
      <ProTable columns={columns}
                request={getListData}
                key='studentLessons'
                actionRef={autoReLoad}
      />
    </>
  );

}

export default StudentLessonTable;
