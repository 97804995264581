import * as React from 'react';
import {PageContainer} from "@ant-design/pro-components";
import {useState} from "react";
import EmployeeTable from "@/components/inner/employee";
import RoleTree from "@/components/role";

const EmployeeTab: React.FC = () => {

  const [tabKey, setTabKey] = useState<string>('employee');

  const [formVisible, setFormVisible] = useState<boolean>(false);

  // tab
  const tabList = [
    {
      key: 'employee',
      tab: '员工管理',
    },
    {
      key: 'role',
      tab: '角色管理',
    },
  ];

  const renderChildrenByTabKey = () => {
    if (tabKey === 'employee') {
      return <EmployeeTable handleIsVisible={setFormVisible}/>;
    } else if (tabKey === 'role') {
      return <RoleTree/>;
    }
    return null;
  };

  const handleTabChange = (key: string) => {
    setTabKey(key);
  };

  return (
    <PageContainer
      tabList={tabList}
      tabActiveKey={tabKey}
      onTabChange={handleTabChange}
    >
      {renderChildrenByTabKey()}
    </PageContainer>
  );

}


export default EmployeeTab;

